import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createClient } from '@supabase/supabase-js';
import Button from '../elements/Button';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_API_KEY
);

const EmailPopup = ({ isFooter = false }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasShown, setHasShown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const wasSubscribed = localStorage.getItem('blast_subscribed');
    if (wasSubscribed && !isFooter) {
      return;
    }

    if (isFooter) {
      setIsVisible(true);
      return;
    }

    const handleScroll = () => {
      if (hasShown) return;

      const numbersSection = document.querySelector('.numbers-section');
      if (numbersSection) {
        const rect = numbersSection.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        
        // Show popup when numbers section is scrolled past
        if (rect.bottom < windowHeight * 0.3) {
          console.log('Showing popup - scrolled past numbers section');
          setIsVisible(true);
          setHasShown(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    // Check initial scroll position
    setTimeout(handleScroll, 500);
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasShown, isFooter]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setError('');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    try {
      setIsSubmitting(true);
      
      const { data, error: supabaseError } = await supabase
        .from('email_subscribers')
        .insert([
          { 
            email: email.toLowerCase().trim(),
            subscribed_at: new Date().toISOString()
          }
        ])
        .select();

      if (supabaseError) throw supabaseError;
      
      localStorage.setItem('blast_subscribed', 'true');
      
      setIsSubmitted(true);
      if (!isFooter) {
        setTimeout(() => {
          setIsVisible(false);
        }, 2000);
      }
    } catch (error) {
      console.error('Error saving email:', error);
      if (error.code === '23505') {
        setError('This email is already subscribed!');
        localStorage.setItem('blast_subscribed', 'true');
      } else {
        setError('Failed to subscribe. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isVisible) return null;

  if (isFooter) {
    return (
      <div className="w-full">
        {!isSubmitted ? (
          <>
            <h3 className="text-sm font-semibold text-gray-800 mb-1">Subscribe to our newsletter</h3>
            <p className="text-xs text-gray-600 mb-3">The latest news, articles, and resources, sent to your inbox weekly.</p>
            <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-3" noValidate>
              <div className="flex-grow">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className={`w-full h-10 px-3 text-sm text-gray-900 bg-white border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  required
                />
                {error && <p className="mt-1 text-xs text-red-600">{error}</p>}
              </div>
              <Button 
                type="submit"
                color="primary"
                disabled={isSubmitting}
                className="h-10 whitespace-nowrap"
              >
                {isSubmitting ? 'Subscribing...' : 'Subscribe'}
              </Button>
            </form>
          </>
        ) : (
          <div className="text-center py-3">
            <h3 className="text-sm font-semibold text-gray-800 mb-1">Thank you for subscribing! 🎉</h3>
            <p className="text-xs text-gray-600">Check your inbox for updates.</p>
          </div>
        )}
      </div>
    );
  }

  return ReactDOM.createPortal(
    <div className="fixed bottom-5 right-5 bg-white rounded-xl shadow-2xl p-6 w-[320px] animate-slide-up z-50 overflow-hidden">
      {!isSubmitted ? (
        <form onSubmit={handleSubmit} noValidate className="mt-0">
          <button 
            type="button" 
            className="absolute top-2 right-2 text-gray-400 hover:text-gray-500 leading-none"
            onClick={() => setIsVisible(false)}
          >
            <span className="text-2xl leading-none block">×</span>
          </button>
          <h5 className="mt-0">Join Our Research Community</h5>
          <div className="space-y-2">
            <p className="text-sm text-gray-600">An intensive online AI research program for talented high school students. 2025 applications are currently being accepted.</p>
          </div>
          <div className="space-y-3">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your email address"
              className={`w-full px-4 py-2.5 text-gray-900 bg-gray-50 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
              required
            />
            {error && <p className="text-sm text-red-600">{error}</p>}
            <Button 
              type="submit"
              color="primary"
              disabled={isSubmitting}
              className="w-full"
            >
              {isSubmitting ? 'Subscribing...' : 'Apply Now'}
            </Button>
          </div>
        </form>
      ) : (
        <div className="text-center py-4">
          <h3 className="text-xl font-semibold text-gray-900 mb-2">Welcome Aboard! 🎉</h3>
          <p className="text-gray-600">You're now part of our research community. Check your inbox soon!</p>
        </div>
      )}
    </div>,
    document.body
  );
};

export default EmailPopup; 