import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const AIResearchProgram = () => {
  const pageTitle = "High School AI Research Program | Publish with MIT Mentors";
  const pageDescription = "Join elite high school researchers in our 8-week AI program. Work with Stanford/MIT mentors, publish original research, and build your college portfolio.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "BLAST AI Research Program",
    "description": "An intensive research program where high school students conduct original AI research",
    "provider": {
      "@type": "Organization",
      "name": "BLAST AI",
      "sameAs": "https://blastai.org"
    },
    "courseCode": "BLAST-RESEARCH-2025",
    "educationalLevel": "High School",
    "hasCourseInstance": {
      "@type": "CourseInstance",
      "courseMode": "online",
      "duration": "P8W",
      "startDate": "2025-06-17",
      "endDate": "2025-08-12",
      "price": 1460,
      "priceCurrency": "USD"
    },
    "teaches": [
      "Machine Learning",
      "Deep Learning",
      "Neural Networks",
      "Research Methodology",
      "Scientific Writing",
      "Data Analysis"
    ],
    "educationalCredentialAwarded": "Research Publication"
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blastai.org/ai-research-program" />
        <link rel="canonical" href="https://blastai.org/ai-research-program" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <h1>{pageTitle}</h1>
      <Research />
    </>
  );
};

export default AIResearchProgram; 