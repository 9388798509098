import React from 'react';
import { Helmet } from 'react-helmet-async';
import ApplicationTimeline from '../components/sections/ApplicationTimeline';
import ResearchSplit from '../components/sections/ResearchSplit';
import ResearchCarousel from '../components/sections/ResearchCarosel2';
import FAQ from '../components/sections/FAQ';
import Image from '../components/elements/Image';
import ResearchInfo from '../components/sections/research/ResearchInfo';
import Publications from '../components/sections/research/Publications';

class Research extends React.Component {
  render() {
    const pageTitle = "High School AI Research Program | Stanford Mentored Research | BLAST AI";
    const pageDescription = "High school AI research program with Stanford/MIT mentors. Publish AI papers, conduct machine learning research, build neural networks. Summer research program for high school students 2025.";

    const structuredData = {
      "@context": "https://schema.org",
      "@type": "EducationalProgram",
      "name": "BLAST AI Research Program",
      "description": pageDescription,
      "provider": {
        "@type": "Organization",
        "name": "BLAST AI",
        "url": "https://blastai.org",
        "sameAs": [
          "https://www.linkedin.com/company/blast-ai",
          "https://twitter.com/blast_ai"
        ]
      },
      "programType": "Research Program",
      "educationalProgramMode": "online",
      "timeOfYear": "Summer",
      "applicationDeadline": "2025-04-30",
      "educationalLevel": "High School",
      "offers": {
        "@type": "Offer",
        "price": "1460",
        "priceCurrency": "USD",
        "availability": "https://schema.org/InStock",
        "validFrom": "2024-02-01"
      },
      "teaches": [
        "AI Research",
        "Machine Learning",
        "Deep Learning",
        "Research Methodology",
        "Scientific Writing",
        "Academic Publishing"
      ]
    };

    return (
      <React.Fragment>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          
          {/* OpenGraph tags */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://blastai.org/research" />
          <meta property="og:image" content="https://blastai.org/blast-ai-logo.png" />
          <meta property="og:site_name" content="BLAST AI" />
          
          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
          <meta name="twitter:image" content="https://blastai.org/blast-ai-logo.png" />
          
          {/* Additional SEO tags */}
          <meta name="keywords" content="ai research program, high school research, stanford mentorship, mit mentorship, ai research opportunities, machine learning research, high school ai program" />
          <meta name="author" content="BLAST AI" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://blastai.org/research" />
          
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
        <ResearchInfo hasBgColor invertColor />
        <ResearchCarousel />
        <ResearchSplit />
        <ApplicationTimeline topDivider className="has-bg-color-cut illustration-section-02"/>
        <Publications hasBgColor invertColor />
        <FAQ />
      </React.Fragment>
    );
  }
}

export default Research;