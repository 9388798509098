import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const YouthAIInstitute = () => {
  const pageTitle = "Youth AI Institute 2025 | Advanced AI Training for Teens";
  const pageDescription = "Transform your future with our comprehensive AI institute for youth. Master machine learning, neural networks, and AI development with expert mentorship. Summer 2025 enrollment open.";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* OpenGraph tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blastai.org/youth-ai-institute" />
        <meta property="og:image" content="https://blastai.org/youth-ai-institute.png" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        
        {/* Additional meta tags */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta http-equiv="content-language" content="en-US" />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large" />
        <link rel="canonical" href="https://blastai.org/youth-ai-institute" />
      </Helmet>
      
      <div className="landing-page">
        <h1 className="main-title">{pageTitle}</h1>
        <Research />
      </div>
    </>
  );
};

export default YouthAIInstitute; 