import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const AIBootcamp = () => {
  const pageTitle = "AI Bootcamp 2025 | Intensive Machine Learning Training";
  const pageDescription = "AI bootcamp with Python programming, machine learning, deep learning, neural networks, TensorFlow, PyTorch. Stanford mentors, hands-on projects, computer vision, NLP training. 4-week intensive program.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "BLAST AI Bootcamp",
    "description": "An intensive AI bootcamp designed for high school students",
    "provider": {
      "@type": "Organization",
      "name": "BLAST AI",
      "sameAs": "https://blastai.org"
    },
    "courseCode": "BLAST-BOOTCAMP-2025",
    "educationalLevel": "High School",
    "hasCourseInstance": {
      "@type": "CourseInstance",
      "courseMode": "online",
      "duration": "P4W",
      "startDate": "2025-06-17",
      "endDate": "2025-07-15",
      "price": 960,
      "priceCurrency": "USD"
    },
    "teaches": [
      "AI Fundamentals",
      "Python Programming",
      "Machine Learning Basics",
      "Neural Networks",
      "Practical AI Applications",
      "Project Development"
    ],
    "educationalCredentialAwarded": "Certificate of Completion"
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* OpenGraph tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blastai.org/ai-bootcamp" />
        <meta property="og:image" content="https://blastai.org/blast-ai-bootcamp.png" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        
        {/* Keywords and SEO */}
        <meta name="keywords" content="ai bootcamp, machine learning bootcamp, python programming bootcamp, deep learning training, neural networks course, tensorflow pytorch training, computer vision bootcamp, nlp training, stanford ai mentorship, intensive ai program" />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large" />
        <link rel="canonical" href="https://blastai.org/ai-bootcamp" />

        {/* Add CSS for visually hidden elements */}
        <style>
          {`
            .visually-hidden {
              position: absolute;
              width: 1px;
              height: 1px;
              padding: 0;
              margin: -1px;
              overflow: hidden;
              clip: rect(0, 0, 0, 0);
              white-space: nowrap;
              border: 0;
            }
          `}
        </style>

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="landing-page">
        <h1 className="visually-hidden">{pageTitle}</h1>
        <Research />
      </div>
    </>
  );
};

export default AIBootcamp;