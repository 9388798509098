import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSplit from '../../components/sections/HeroSplit';
import FeaturesSplit from '../../components/sections/FeaturesSplit';
import Testimonial from '../../components/sections/Testimonial';
import FAQ from '../../components/sections/FAQ';
import Image from '../../components/elements/Image';

const pageTitle = "Data Science Summer Institute | High School AI Analytics Program";
const pageDescription = "Data science institute with Python, machine learning, statistical analysis, data visualization, big data processing. Stanford mentors, hands-on projects, data analytics training.";

const structuredData = {
  "@context": "https://schema.org",
  "@type": "EducationalOrganization",
  "name": "Data Science Institute",
  "description": pageDescription,
  "url": "https://blastai.org/data-science-summer-institute",
  "educationalLevel": "High School",
  "offers": {
    "@type": "Offer",
    "category": "Data Science Education",
    "availability": "https://schema.org/InStock"
  },
  "provider": {
    "@type": "Organization",
    "name": "BLAST AI",
    "sameAs": "https://blastai.org"
  }
};

class DataScienceInstitute extends React.Component {
  render() {
    return (
      <main className="data-science-institute-page">
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <link rel="canonical" href="https://blastai.org/data-science-summer-institute" />
          
          {/* OpenGraph Tags */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://blastai.org/data-science-summer-institute" />
          <meta property="og:image" content="https://blastai.org/data-science-institute.jpg" />
          
          {/* Twitter Card Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />

          {/* Keywords and SEO */}
          <meta name="keywords" content="data science institute, data analytics program, python data science, machine learning training, statistical analysis course, data visualization training, big data processing, data science bootcamp, stanford data science, high school data science" />
          <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large" />

          {/* Add CSS for visually hidden elements */}
          <style>
            {`
              .visually-hidden {
                position: absolute;
                width: 1px;
                height: 1px;
                padding: 0;
                margin: -1px;
                overflow: hidden;
                clip: rect(0, 0, 0, 0);
                white-space: nowrap;
                border: 0;
              }
            `}
          </style>

          <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        </Helmet>
        
        <h1 className="visually-hidden">{pageTitle}</h1>
        
        <header>
          <div className="container-xl">
            <div className="hero-content">
              <h2 className="mt-20 mb-16 reveal-from-bottom text-h2" data-reveal-delay="200">
                Data Science Summer Institute for High School Students
              </h2>
              <div className="container-lg">
                <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  Join our intensive data science program and learn from Stanford and MIT mentors. Master machine learning, statistics, and data analysis while building real-world projects.
                </p>
              </div>
            </div>
          </div>
        </header>
        
        <article>
          <div className="container-xl">
            <div className="features-split-inner section-inner has-top-divider mt-10">
              <div className="split-wrap">
                
                <div className="split-item">
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Comprehensive Curriculum</h3>
                    <p className="m-0">
                      Master essential data science skills:
                      <ul>
                        <li>Statistical Analysis</li>
                        <li>Machine Learning Fundamentals</li>
                        <li>Data Visualization</li>
                        <li>Python Programming</li>
                        <li>Big Data Processing</li>
                        <li>Deep Learning Applications</li>
                      </ul>
                    </p>
                  </div>
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/polling-track.jpg`}
                      alt="Data Science Curriculum"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                </div>

                <div className="split-item">
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/smiles.jpg`}
                      alt="Hands-on Projects"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Hands-on Projects</h3>
                    <p className="m-0">
                      Work on real-world data science projects:
                      <ul>
                        <li>Medical Data Analysis</li>
                        <li>Environmental Data Science</li>
                        <li>Social Media Analytics</li>
                        <li>Financial Data Modeling</li>
                        <li>Sports Analytics</li>
                        <li>Urban Planning Data</li>
                      </ul>
                    </p>
                  </div>
                </div>

                <div className="split-item">
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Industry Tools</h3>
                    <p className="m-0">
                      Learn professional data science tools:
                      <ul>
                        <li>Python Data Science Stack</li>
                        <li>SQL and Databases</li>
                        <li>Jupyter Notebooks</li>
                        <li>Cloud Computing Platforms</li>
                        <li>Version Control Systems</li>
                        <li>Data Visualization Tools</li>
                      </ul>
                    </p>
                  </div>
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/scale.jpg`}
                      alt="Industry Tools"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                </div>

                <div className="split-item">
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/udemy.jpg`}
                      alt="Career Development"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Career Development</h3>
                    <p className="m-0">
                      Prepare for your future:
                      <ul>
                        <li>Industry Project Experience</li>
                        <li>Professional Portfolio</li>
                        <li>Technical Interview Prep</li>
                        <li>Networking Opportunities</li>
                        <li>Career Path Guidance</li>
                        <li>Internship Connections</li>
                      </ul>
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </article>
 
        <FAQ />
      </main>
    );
  }
}

export default DataScienceInstitute; 