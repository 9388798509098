import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const DeepLearningHS = () => {
  const pageTitle = "Deep Learning for High School | Neural Network Training Program";
  const pageDescription = "High school deep learning program with neural networks, computer vision, NLP, PyTorch, TensorFlow. Learn AI model design, transformer architectures, deep learning projects. Stanford mentors.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "EducationalEvent",
    "name": "High School Deep Learning Summer Program 2025",
    "description": pageDescription,
    "startDate": "2025-06-15",
    "endDate": "2025-08-15",
    "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
    "eventStatus": "https://schema.org/EventScheduled",
    "organizer": {
      "@type": "Organization",
      "name": "BLAST AI",
      "url": "https://blastai.org"
    },
    "location": {
      "@type": "VirtualLocation",
      "url": "https://blastai.org/deep-learning-high-school"
    },
    "offers": {
      "@type": "Offer",
      "price": "1460",
      "priceCurrency": "USD",
      "availability": "https://schema.org/InStock",
      "validFrom": "2024-02-01"
    },
    "teaches": [
      "Deep Learning",
      "Neural Networks",
      "Convolutional Networks",
      "Recurrent Networks",
      "Transformer Models",
      "PyTorch",
      "TensorFlow",
      "Deep Learning Applications"
    ]
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* OpenGraph tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blastai.org/deep-learning-high-school" />
        <meta property="og:image" content="https://blastai.org/deep-learning-hs.jpg" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        
        {/* Keywords and SEO */}
        <meta name="keywords" content="deep learning high school, neural networks program, computer vision training, nlp course teens, pytorch tensorflow course, ai model design, transformer architecture, deep learning projects, stanford ai mentors, high school ml program" />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large" />
        <link rel="canonical" href="https://blastai.org/deep-learning-high-school" />

        {/* Add CSS for visually hidden elements */}
        <style>
          {`
            .visually-hidden {
              position: absolute;
              width: 1px;
              height: 1px;
              padding: 0;
              margin: -1px;
              overflow: hidden;
              clip: rect(0, 0, 0, 0);
              white-space: nowrap;
              border: 0;
            }
          `}
        </style>

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="landing-page">
        <h1 className="visually-hidden">{pageTitle}</h1>
        <Research />
      </div>
    </>
  );
};

export default DeepLearningHS; 