import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const DeepLearningCourse = () => {
  const pageTitle = "Deep Learning Summer Course | Neural Networks Training Program";
  const pageDescription = "Deep learning course with neural networks, computer vision, NLP, PyTorch, TensorFlow. Learn AI architecture design, model training, deep learning applications. Stanford mentors guide you.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "BLAST AI Deep Learning Summer Course",
    "description": pageDescription,
    "provider": {
      "@type": "Organization",
      "name": "BLAST AI",
      "sameAs": "https://blastai.org"
    },
    "courseCode": "BLAST-DL-2025",
    "educationalLevel": "High School",
    "hasCourseInstance": {
      "@type": "CourseInstance",
      "courseMode": "online",
      "duration": "P6W",
      "startDate": "2025-06-17",
      "endDate": "2025-07-29",
      "price": 1260,
      "priceCurrency": "USD"
    },
    "teaches": [
      "Deep Learning Fundamentals",
      "Neural Networks",
      "Computer Vision",
      "Natural Language Processing",
      "Deep Learning Projects",
      "Model Architecture Design"
    ]
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* OpenGraph Tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blastai.org/deep-learning-summer-course" />
        <meta property="og:image" content="https://blastai.org/deep-learning-course.jpg" />
        
        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        
        {/* Keywords and SEO */}
        <meta name="keywords" content="deep learning course, neural networks training, computer vision course, nlp training, pytorch tensorflow, ai architecture design, model training course, deep learning applications, stanford ai mentors, summer ai program" />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large" />
        <link rel="canonical" href="https://blastai.org/deep-learning-summer-course" />

        {/* Add CSS for visually hidden elements */}
        <style>
          {`
            .visually-hidden {
              position: absolute;
              width: 1px;
              height: 1px;
              padding: 0;
              margin: -1px;
              overflow: hidden;
              clip: rect(0, 0, 0, 0);
              white-space: nowrap;
              border: 0;
            }
          `}
        </style>

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="landing-page">
        <h1 className="visually-hidden">{pageTitle}</h1>
        <Research />
      </div>
    </>
  );
};

export default DeepLearningCourse; 