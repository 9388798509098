import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSplit from '../../components/sections/HeroSplit';
import FeaturesSplit from '../../components/sections/FeaturesSplit';
import Testimonial from '../../components/sections/Testimonial';
import FAQ from '../../components/sections/FAQ';
import Image from '../../components/elements/Image';

const pageTitle = "Elite AI Summer School for High School Students";
const pageDescription = "Join our intensive AI summer program and learn from Stanford and MIT mentors. Master deep learning, conduct research, and build cutting-edge AI projects.";

const structuredData = {
  "@context": "http://schema.org",
  "@type": "EducationalOrganization",
  "name": "AI Summer School",
  "description": pageDescription,
  "educationalProgrammingModel": "AI and Machine Learning",
  "audience": {
    "@type": "EducationalAudience",
    "educationalRole": "High School Student"
  }
};

class AISummerSchool extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
        <main className="ai-summer-school-page">
          <header>
            <div className="container-xl">
              <div className="hero-content">
                <h1 className="mt-20 mb-16 reveal-from-bottom h2-size" data-reveal-delay="200">
                  Elite AI Summer School for High School Students
                </h1>
                <div className="container-lg">
                  <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                    Join our intensive AI summer program and learn from Stanford and MIT mentors. Master deep learning, conduct research, and build cutting-edge AI projects.
                  </p>
                </div>
              </div>
            </div>
          </header>
          
          <article>
            <div className="container-xl">
              <div className="features-split-inner section-inner has-top-divider mt-10">
                <div className="split-wrap">
                  
                  <div className="split-item">
                    <div className="split-item-content center-content-mobile">
                      <h3 className="mt-0 mb-16">Comprehensive AI Curriculum</h3>
                      <p className="m-0">
                        Master advanced AI concepts:
                        <ul>
                          <li>Deep Learning Architectures</li>
                          <li>Neural Network Design</li>
                          <li>Computer Vision Applications</li>
                          <li>Natural Language Processing</li>
                          <li>Reinforcement Learning</li>
                        </ul>
                      </p>
                    </div>
                    <div className="split-item-image center-content-mobile">
                      <Image
                        src={`${process.env.REACT_APP_CLOUDFLARE_URL}/viz.jpg`}
                        alt="AI Curriculum"
                        width={528}
                        height={396}
                        style={{objectFit: 'cover'}} />
                    </div>
                  </div>

                  <div className="split-item">
                    <div className="split-item-image center-content-mobile">
                      <Image
                        src={`${process.env.REACT_APP_CLOUDFLARE_URL}/robot-rocket.jpg`}
                        alt="Hands-on Projects"
                        width={528}
                        height={396}
                        style={{objectFit: 'cover'}} />
                    </div>
                    <div className="split-item-content center-content-mobile">
                      <h3 className="mt-0 mb-16">Hands-on Projects</h3>
                      <p className="m-0">
                        Build real-world AI applications:
                        <ul>
                          <li>Medical Image Analysis</li>
                          <li>Autonomous Systems</li>
                          <li>Language Models</li>
                          <li>Robotics Control</li>
                          <li>Game AI Development</li>
                        </ul>
                      </p>
                    </div>
                  </div>

                  <div className="split-item">
                    <div className="split-item-content center-content-mobile">
                      <h3 className="mt-0 mb-16">Expert Mentorship</h3>
                      <p className="m-0">
                        Learn from industry leaders:
                        <ul>
                          <li>Stanford AI Researchers</li>
                          <li>MIT Graduate Students</li>
                          <li>Industry Professionals</li>
                          <li>Research Scientists</li>
                          <li>AI Engineers</li>
                        </ul>
                      </p>
                    </div>
                    <div className="split-item-image center-content-mobile">
                      <Image
                        src={`${process.env.REACT_APP_CLOUDFLARE_URL}/mike-presenting.png`}
                        alt="Expert Mentorship"
                        width={528}
                        height={396}
                        style={{objectFit: 'cover'}} />
                    </div>
                  </div>

                  <div className="split-item">
                    <div className="split-item-image center-content-mobile">
                      <Image
                        src={`${process.env.REACT_APP_CLOUDFLARE_URL}/voyager.jpg`}
                        alt="College Preparation"
                        width={528}
                        height={396}
                        style={{objectFit: 'cover'}} />
                    </div>
                    <div className="split-item-content center-content-mobile">
                      <h3 className="mt-0 mb-16">College Preparation Benefits</h3>
                      <p className="m-0">
                        Boost your college applications:
                        <ul>
                          <li>Pre-College Research Experience</li>
                          <li>Published AI Research Papers</li>
                          <li>Letters of Recommendation</li>
                          <li>Technical Portfolio Development</li>
                          <li>College Admissions Boost</li>
                        </ul>
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </article>

          <FAQ />
        </main>
      </>
    );
  }
}

export default AISummerSchool; 