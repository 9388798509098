import React, { useState, useEffect, useRef } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/splide/dist/css/splide.min.css';

// Import all images from the colleges directory
function importAll(r) {
  return r.keys().map(r);
}

const collegeLogos = importAll(require.context('../../assets/images/colleges', false, /\.(png|jpe?g|svg)$/));

const stats = [
  { 
    id: 0, 
    name: 'Global Impact',
    description: 'High Schools Represented in Our Community',
    value: 200, 
    speed: 20 
  },
];

export default function Numbers({ className }) {
  const [counters, setCounters] = useState(stats.map(stat => ({ ...stat, currentValue: 0, isVisible: false })));
  const counterRefs = useRef(counters.map(() => React.createRef()));

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute('data-id');
        const isVisible = entry.isIntersecting;
        setCounters(currentCounters =>
          currentCounters.map(counter => {
            if (String(counter.id) === id) {
              return { ...counter, isVisible: isVisible };
            }
            return counter;
          })
        );
      });
    }, { threshold: 0.1 });

    const currentCounters = counterRefs.current;
    currentCounters.forEach(ref => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      currentCounters.forEach(ref => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounters(currentCounters =>
        currentCounters.map(counter => {
          if (counter.isVisible && counter.currentValue < counter.value) {
            const nextValue = Math.min(counter.value, counter.currentValue + counter.speed);
            return { ...counter, currentValue: nextValue };
          }
          return counter;
        })
      );
    }, 50);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`py-24 ${className || ''}`}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {/* <div className="mx-auto max-w-2xl text-center">
          {counters.map((counter, index) => (
            <div
              key={counter.id}
              className="mx-auto flex flex-col items-center gap-y-2"
              ref={counterRefs.current[index]}
              data-id={counter.id}
            >
              <h2 className="text-6xl font-bold tracking-tight text-gray-900 sm:text-7xl">
                {counter.currentValue}
                <span className="text-indigo-600">+</span>
              </h2>
              <p className="text-lg font-semibold text-indigo-600">{counter.name}</p>
              <p className="text-base text-gray-600">{counter.description}</p>
            </div>
          ))}
        </div> */}

        <div className="mt-0">
          <div className="text-center mb-12">
            <h3 className="text-gray-900">
              We've guided students from 250+ high schools
            </h3>
            <p className="mt-2 text-lg text-gray-600">
              Our alumni continue their journey at prestigious institutions including:
            </p>
          </div>
          
          <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-200" />
            </div>
            <Splide
              options={{
                type: 'loop',
                gap: '3rem',
                drag: 'free',
                arrows: false,
                pagination: false,
                perPage: 5,
                autoWidth: true,
                height: '7rem',
                autoScroll: {
                  pauseOnHover: false,
                  pauseOnFocus: false,
                  rewind: false,
                  speed: 0.5
                },
                breakpoints: {
                  640: {
                    perPage: 2,
                    gap: '1.5rem',
                  },
                  768: {
                    perPage: 3,
                    gap: '2rem',
                  },
                  1024: {
                    perPage: 4,
                    gap: '2.5rem',
                  },
                }
              }}
              extensions={{ AutoScroll }}
            >
              {collegeLogos.map((logo, index) => (
                <SplideSlide key={index}>
                  <div className="flex items-center justify-center h-full px-4 py-2 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
                    <img
                      src={logo}
                      alt="University Logo"
                      className="h-12 w-auto object-contain filter transition-all duration-200"
                    />
                  </div>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </div>
      </div>
    </div>
  );
}
