import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const AISummerProgramHS = () => {
  const pageTitle = "High School AI Summer Program | Learn from Stanford Mentors";
  const pageDescription = "Transform your future with our elite AI summer program. Master AI, robotics & ML through hands-on projects guided by Stanford mentors. Perfect for tech-passionate teens.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "EducationalEvent",
    "name": "AI Summer Program for High School Students 2025",
    "description": pageDescription,
    "startDate": "2025-06-15",
    "endDate": "2025-08-15",
    "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
    "eventStatus": "https://schema.org/EventScheduled",
    "organizer": {
      "@type": "Organization",
      "name": "BLAST AI",
      "url": "https://blastai.org"
    },
    "offers": {
      "@type": "Offer",
      "price": "1460",
      "priceCurrency": "USD",
      "availability": "https://schema.org/InStock",
      "validFrom": "2024-02-01"
    },
    "teaches": [
      "Artificial Intelligence",
      "Machine Learning",
      "Robotics",
      "Computer Vision",
      "Natural Language Processing",
      "Neural Networks",
      "AI Ethics",
      "Python Programming"
    ]
  };

  const aggregateRating = {
    "@context": "https://schema.org",
    "@type": "AggregateRating",
    "itemReviewed": {
      "@type": "Course",
      "name": "AI Summer Program for High School Students"
    },
    "ratingValue": "4.8",
    "bestRating": "5",
    "worstRating": "1",
    "ratingCount": "183",
    "reviewCount": "112"
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(aggregateRating)}
        </script>
      </Helmet>
      <h1>High School AI Summer Program | Learn from Stanford Mentors</h1>
      <Research />
    </>
  );
};

export default AISummerProgramHS; 