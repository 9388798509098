import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSplit from '../../components/sections/HeroSplit';
import FeaturesSplit from '../../components/sections/FeaturesSplit';
import Testimonial from '../../components/sections/Testimonial';
import FAQ from '../../components/sections/FAQ';
import Image from '../../components/elements/Image';

const pageTitle = "AI Research Paper Guide | High School Publication Guide";
const pageDescription = "Research paper writing guide for high school students. Scientific writing, methodology, literature review, data analysis, paper publication, conference submissions, peer review process, academic writing.";

const structuredData = {
  "@context": "https://schema.org",
  "@type": "Article",
  "headline": pageTitle,
  "description": pageDescription,
  "author": {
    "@type": "Organization",
    "name": "BLAST AI"
  },
  "datePublished": "2024-02-20",
  "dateModified": "2024-02-20",
  "publisher": {
    "@type": "Organization",
    "name": "BLAST AI",
    "logo": {
      "@type": "ImageObject",
      "url": "https://blastai.org/blast-ai-logo.png"
    }
  },
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://blastai.org/research-paper-guide"
  }
};

class ResearchPaperGuide extends React.Component {
  render() {
    return (
      <main className="research-paper-guide-page">
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <link rel="canonical" href="https://blastai.org/research-paper-guide" />
          
          {/* OpenGraph Tags */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content="https://blastai.org/research-paper-guide" />
          <meta property="og:image" content="https://blastai.org/research-guide.jpg" />
          
          {/* Twitter Card Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
          
          {/* Keywords and SEO */}
          <meta name="keywords" content="research paper guide, scientific writing guide, paper publication guide, academic writing help, research methodology, literature review guide, data analysis guide, conference submission guide, peer review process, high school research" />
          <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large" />
          
          {/* Add CSS for visually hidden elements */}
          <style>
            {`
              .visually-hidden {
                position: absolute;
                width: 1px;
                height: 1px;
                padding: 0;
                margin: -1px;
                overflow: hidden;
                clip: rect(0, 0, 0, 0);
                white-space: nowrap;
                border: 0;
              }
            `}
          </style>
          
          <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        </Helmet>
        
        <h1 className="visually-hidden">{pageTitle}</h1>
        
        <header>
          <div className="container-xl">
            <div className="hero-content">
              <h2 className="mt-20 mb-16 reveal-from-bottom h2-style" data-reveal-delay="200">
                How to Write and Publish AI Research Papers in High School
              </h2>
              <div className="container-lg">
                <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  Learn how to write and publish research papers with guidance from Stanford and MIT mentors. Master the research process and share your findings with the scientific community.
                </p>
              </div>
            </div>
          </div>
        </header>
        
        <article>
          <div className="container-xl">
            <div className="features-split-inner section-inner has-top-divider mt-10">
              <div className="split-wrap">
                
                <div className="split-item">
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Research Paper Writing Process</h3>
                    <p className="m-0">
                      Master the research writing process:
                      <ul>
                        <li>Literature Review and Background Research</li>
                        <li>Methodology Development</li>
                        <li>Experiment Design and Implementation</li>
                        <li>Data Analysis and Visualization</li>
                        <li>Paper Writing and Peer Review</li>
                      </ul>
                    </p>
                  </div>
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/underwater_paper.jpg`}
                      alt="Research Writing Process"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                </div>

                <div className="split-item">
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/rl_paper.jpg`}
                      alt="Research Areas"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Research Areas for High School Students</h3>
                    <p className="m-0">
                      Popular research topics include:
                      <ul>
                        <li>Galaxy Anomaly Detection</li>
                        <li>AI for Medical Applications</li>
                        <li>Natural Language Processing</li>
                        <li>Computer Vision Research</li>
                        <li>AI Robotics and Control</li>
                        <li>Quantum Computing Applications</li>
                      </ul>
                    </p>
                  </div>
                </div>

                <div className="split-item">
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Expert Mentorship Support</h3>
                    <p className="m-0">
                      Get guidance from experienced mentors:
                      <ul>
                        <li>Weekly 1-on-1 Mentoring Sessions</li>
                        <li>Research Direction and Planning</li>
                        <li>Writing Workshops and Feedback</li>
                        <li>Presentation Skills Training</li>
                        <li>Publication Strategy Advice</li>
                      </ul>
                    </p>
                  </div>
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/research-schedule.jpg`}
                      alt="Mentorship Support"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                </div>

                <div className="split-item">
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/iclr.jpg`}
                      alt="Publication Opportunities"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Publication Opportunities</h3>
                    <p className="m-0">
                      Share your research findings through:
                      <ul>
                        <li>IEEE Conference Submissions</li>
                        <li>ArXiv Preprint Publications</li>
                        <li>High School Research Journals</li>
                        <li>International AI Conferences</li>
                        <li>Research Symposiums</li>
                      </ul>
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </article>
 
        <FAQ />
      </main>
    );
  }
}

export default ResearchPaperGuide; 