import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import { useAnalytics } from '../../hooks/useAnalytics';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,      
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const { trackEvent } = useAnalytics();

  const handleApplyClick = () => {
    trackEvent('apply_click', {
      button_location: 'cta',
      page_path: window.location.pathname
    });
  };

  const outerClasses = classNames(
    'cta section center-content-mobile',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container pt-5">
        <div className={innerClasses}>
          <div className="cta-slogan">
            <h3 className="m-0">
              2025 Summer Applications Are Now Open!
            </h3>
          </div>
          <div className="mb-8">
            <Button tag="a" color="dark" wide href="/apply" onClick={handleApplyClick}>Apply Now</Button>
          </div>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;