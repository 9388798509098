import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const MLHighSchoolCamp = () => {
  const pageTitle = "Machine Learning High School Summer Camp | ML for Teens 2025";
  const pageDescription = "Elite machine learning summer camp designed for high school students. Learn ML, deep learning, and neural networks with Stanford mentors. Join the #1 rated machine learning program for high school students.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "EducationalEvent",
    "name": "Machine Learning High School Summer Camp 2025",
    "description": pageDescription,
    "startDate": "2025-06-15",
    "endDate": "2025-08-15",
    "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
    "eventStatus": "https://schema.org/EventScheduled",
    "organizer": {
      "@type": "Organization",
      "name": "BLAST AI",
      "url": "https://blastai.org"
    },
    "offers": {
      "@type": "Offer",
      "price": "1460",
      "priceCurrency": "USD",
      "availability": "https://schema.org/InStock",
      "validFrom": "2024-02-01"
    },
    "teaches": [
      "Machine Learning Fundamentals",
      "Deep Neural Networks",
      "Python Programming",
      "Data Science",
      "TensorFlow & PyTorch",
      "Computer Vision",
      "Natural Language Processing",
      "ML Project Development"
    ]
  };

  const aggregateRating = {
    "@context": "https://schema.org",
    "@type": "AggregateRating",
    "itemReviewed": {
      "@type": "Course",
      "name": "Machine Learning High School Summer Camp"
    },
    "ratingValue": "4.9",
    "bestRating": "5",
    "worstRating": "1",
    "ratingCount": "156",
    "reviewCount": "98"
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* OpenGraph tags */}
        <meta property="og:title" content="Machine Learning Summer Camp for High School Students | BLAST AI" />
        <meta property="og:description" content="Join the premier ML summer camp for high school students. Build real machine learning projects with Stanford mentors. Perfect for teens interested in AI and data science." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blastai.org/machine-learning-high-school-camp" />
        <meta property="og:image" content="https://blastai.org/blast-ai-ml-camp.png" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="High School Machine Learning Camp 2025 | Learn ML This Summer" />
        <meta name="twitter:description" content="Elite machine learning summer program for high school students. Learn from Stanford mentors and build cutting-edge ML projects." />
        
        {/* Keywords targeting ML summer camp for high school */}
        <meta name="keywords" content="machine learning high school summer camp, ml camp for high school students, machine learning camp teens, high school ml program, machine learning summer program high school, teen ml camp, high school data science camp, machine learning education high school, ml training for teens, high school ai and ml camp, python machine learning camp, deep learning summer camp high school, neural networks for high school students" />
        
        {/* Additional SEO tags */}
        <meta name="author" content="Stanford & MIT ML Mentors" />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large" />
        <link rel="canonical" href="https://blastai.org/machine-learning-high-school-camp" />

        {/* FAQ Schema */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "What do high school students learn in ML summer camp?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Students learn machine learning fundamentals, deep neural networks, Python programming, TensorFlow, PyTorch, computer vision, and natural language processing through hands-on projects with Stanford mentors."
                }
              },
              {
                "@type": "Question",
                "name": "Do I need prior coding experience for the ML camp?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "No prior coding experience is required. Our program starts with Python basics and gradually progresses to advanced machine learning concepts, making it perfect for beginners."
                }
              },
              {
                "@type": "Question",
                "name": "What projects will I build at ML camp?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "You'll build real-world ML projects including image recognition systems, natural language processors, recommendation engines, and your own custom machine learning application with guidance from mentors."
                }
              }
            ]
          })}
        </script>

        {/* Course Schema */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>

        {/* Aggregate Rating Schema */}
        <script type="application/ld+json">
          {JSON.stringify(aggregateRating)}
        </script>
      </Helmet>
      <Research />
    </>
  );
};

export default MLHighSchoolCamp; 