import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const MLResearchOpportunities = () => {
  const pageTitle = "Machine Learning Research Program | High School AI Research";
  const pageDescription = "Machine learning research opportunities with Stanford mentors. Conduct original AI research, neural networks, deep learning projects, computer vision research. Publish your findings.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "BLAST AI Machine Learning Research Program",
    "description": pageDescription,
    "provider": {
      "@type": "Organization",
      "name": "BLAST AI",
      "sameAs": "https://blastai.org"
    },
    "courseCode": "BLAST-ML-RESEARCH-2025",
    "educationalLevel": "High School",
    "hasCourseInstance": {
      "@type": "CourseInstance",
      "courseMode": "online",
      "duration": "P8W",
      "startDate": "2025-06-17",
      "endDate": "2025-08-12",
      "price": 1460,
      "priceCurrency": "USD"
    },
    "teaches": [
      "Machine Learning Research",
      "Deep Learning",
      "Neural Network Architecture",
      "Research Methodology",
      "Scientific Writing",
      "Data Analysis",
      "AI Model Development",
      "Research Publication"
    ]
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* OpenGraph tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blastai.org/machine-learning-research-opportunities" />
        <meta property="og:image" content="https://blastai.org/ml-research.jpg" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        
        {/* Keywords and SEO */}
        <meta name="keywords" content="machine learning research, high school ai research, stanford ml research, neural networks research, deep learning projects, computer vision research, ai publication opportunities, research mentorship, ml research program, student ai research" />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large" />
        <link rel="canonical" href="https://blastai.org/machine-learning-research-opportunities" />

        {/* Add CSS for visually hidden elements */}
        <style>
          {`
            .visually-hidden {
              position: absolute;
              width: 1px;
              height: 1px;
              padding: 0;
              margin: -1px;
              overflow: hidden;
              clip: rect(0, 0, 0, 0);
              white-space: nowrap;
              border: 0;
            }
          `}
        </style>

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="landing-page">
        <h1 className="visually-hidden">{pageTitle}</h1>
        <Research />
      </div>
    </>
  );
};

export default MLResearchOpportunities;