import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const HighSchoolResearchOpportunities2 = () => {
  const pageTitle = "High School Research Opportunities | Find Research Programs 2025";
  const pageDescription = "Explore top high school research opportunities in AI, science, and technology. Get matched with Stanford & MIT mentors, conduct groundbreaking research, and boost your college applications.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    "name": "High School Research Opportunities Directory",
    "description": pageDescription,
    "provider": {
      "@type": "Organization",
      "name": "BLAST AI",
      "url": "https://blastai.org",
      "sameAs": [
        "https://www.linkedin.com/company/blast-ai",
        "https://twitter.com/blast_ai"
      ]
    },
    "about": {
      "@type": "Thing",
      "name": "High School Research Programs",
      "description": "Comprehensive directory of research opportunities available to high school students"
    },
    "offers": {
      "@type": "AggregateOffer",
      "highPrice": "2000",
      "lowPrice": "0",
      "priceCurrency": "USD",
      "offerCount": "10",
      "offers": [
        {
          "@type": "Offer",
          "name": "AI Research Program",
          "price": "1460",
          "priceCurrency": "USD"
        },
        {
          "@type": "Offer",
          "name": "Machine Learning Research",
          "price": "1460",
          "priceCurrency": "USD"
        },
        {
          "@type": "Offer",
          "name": "Computer Vision Research",
          "price": "1460",
          "priceCurrency": "USD"
        }
      ]
    },
    "mainEntity": {
      "@type": "ItemList",
      "itemListElement": [
        {
          "@type": "Course",
          "name": "AI Research Program",
          "description": "Conduct original AI research with Stanford mentors",
          "provider": "BLAST AI"
        },
        {
          "@type": "Course",
          "name": "Machine Learning Research",
          "description": "Research ML algorithms with MIT researchers",
          "provider": "BLAST AI"
        },
        {
          "@type": "Course",
          "name": "Computer Vision Research",
          "description": "Develop cutting-edge computer vision solutions",
          "provider": "BLAST AI"
        }
      ]
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* OpenGraph tags optimized for research opportunities */}
        <meta property="og:title" content="Find High School Research Opportunities 2025 | Research Programs" />
        <meta property="og:description" content="Browse and compare the best high school research opportunities. Work with top university mentors on real research projects in AI, science, and technology." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blastai.org/high-school-research-opportunities" />
        <meta property="og:image" content="https://blastai.org/blast-ai-logo.png" />
        <meta property="og:site_name" content="BLAST AI" />
        
        {/* Twitter Card tags focused on research */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="High School Research Opportunities & Programs 2025" />
        <meta name="twitter:description" content="Find and compare high school research opportunities. Get matched with university mentors and conduct real research while still in high school." />
        <meta name="twitter:image" content="https://blastai.org/blast-ai-logo.png" />
        
        {/* Additional SEO tags heavily targeting research opportunities */}
        <meta name="keywords" content="high school research opportunities, research programs for high school students, high school research programs, student research opportunities, research opportunities for teens, high school science research, research internships high school, summer research programs, high school stem research, research experience high school, research mentorship programs, high school research projects, research programs near me, research opportunities 2025, high school research summer" />
        <meta name="author" content="BLAST AI" />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <link rel="canonical" href="https://blastai.org/high-school-research-opportunities" />
        
        {/* Additional meta tags for research focus */}
        <meta name="application-name" content="High School Research Directory" />
        <meta name="news_keywords" content="high school research, research opportunities, student research, research programs, science research, stem research" />
        <meta name="format-detection" content="telephone=no" />
        
        {/* Location targeting for research opportunities */}
        <meta name="geo.region" content="US" />
        <meta name="geo.position" content="37.7749;-122.4194" />
        <meta name="ICBM" content="37.7749, -122.4194" />
        
        {/* Additional research-focused meta tags */}
        <meta name="classification" content="Education > Research Programs > High School" />
        <meta name="target" content="High School Students, Parents, Educators" />
        <meta name="coverage" content="Worldwide" />
        <meta name="distribution" content="Global" />
        <meta name="rating" content="General" />
        <meta name="revisit-after" content="7 days" />
        
        {/* Schema.org structured data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>

        {/* Breadcrumb structured data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "High School Programs",
                "item": "https://blastai.org/programs"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Research Opportunities",
                "item": "https://blastai.org/research"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "High School Research Opportunities",
                "item": "https://blastai.org/high-school-research-opportunities"
              }
            ]
          })}
        </script>

        {/* FAQ structured data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "What research opportunities are available for high school students?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "High school students can participate in AI research, machine learning research, computer vision projects, and more with Stanford/MIT mentors."
                }
              },
              {
                "@type": "Question",
                "name": "How can high school students find research opportunities?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Students can find research opportunities through BLAST AI's research programs, university summer programs, and online research platforms."
                }
              },
              {
                "@type": "Question",
                "name": "What are the benefits of high school research?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Benefits include publishing papers, presenting at conferences, strengthening college applications, and gaining real research experience."
                }
              }
            ]
          })}
        </script>
      </Helmet>
      <Research />
    </>
  );
};

export default HighSchoolResearchOpportunities2; 