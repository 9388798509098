import React from 'react';
import { Helmet } from 'react-helmet-async';
import Classes from '../components/sections/Classes';
import Roadmap from '../components/sections/Roadmap';
import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta';
import GenericSection from '../components/sections/GenericSection';
import Button from '../components/elements/Button';
import SectionHeader from '../components/sections/partials/SectionHeader';
import ApplicationTimeline from '../components/sections/ApplicationTimeline';
import BootcampSplit from '../components/sections/bootcamp/BootcampSplit';
import FeaturesTabs from '../components/sections/FeaturesTabs';
import ProjectsCarousel from '../components/sections/bootcamp/ProjectsCarousel';
import CurrSchedule from '../components/sections/bootcamp/CurrSchedule';
import Tracks from '../components/sections/bootcamp/Tracks';
import FAQ from '../components/sections/FAQ';
import FeaturesSplit from '../components/sections/FeaturesSplit';

class Home extends React.Component {
  render() {
    const pageTitle = "AI Bootcamp for High School | Python Machine Learning Course | BLAST AI";
    const pageDescription = "AI bootcamp and machine learning course for high school students. Learn Python programming, neural networks, deep learning, computer vision. 4-week online AI course with Stanford mentors.";

    const structuredData = {
      "@context": "https://schema.org",
      "@type": "Course",
      "name": "BLAST AI Bootcamp",
      "description": pageDescription,
      "provider": {
        "@type": "Organization",
        "name": "BLAST AI",
        "url": "https://blastai.org"
      },
      "courseCode": "BLAST-BOOTCAMP-2025",
      "educationalLevel": "High School",
      "hasCourseInstance": {
        "@type": "CourseInstance",
        "courseMode": "online",
        "duration": "P4W",
        "startDate": "2025-06-17",
        "endDate": "2025-07-15",
        "price": 960,
        "priceCurrency": "USD"
      },
      "teaches": [
        "AI Fundamentals",
        "Python Programming",
        "Machine Learning Basics",
        "Neural Networks",
        "Practical AI Applications",
        "Project Development"
      ]
    };

    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://blastai.org/bootcamp" />
          <link rel="canonical" href="https://blastai.org/bootcamp" />
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
        <React.Fragment>
          <BootcampSplit hasBgColor invertColor />
          <Tracks />
          <FeaturesSplit />
          <FeaturesTabs />
          <FAQ />
        </React.Fragment>
      </>
    );
  }
}

export default Home;