import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Button from '../elements/Button';
import Logo from './partials/Logo';
import { useAnalytics } from '../../hooks/useAnalytics';

const propTypes = {
  active: PropTypes.bool,
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
  invertColor: PropTypes.bool
}

const defaultProps = {
  active: false,
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
  invertColor: false
}

const Header = ({
  className,
  active,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  invertColor,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);
  const nav = useRef(null);
  const hamburger = useRef(null);
  const { trackEvent } = useAnalytics();

  const handleButtonClick = (buttonName) => {
    trackEvent(`${buttonName}_click`, {
      button_location: 'header',
      page_path: window.location.pathname
    });
  };

  useEffect(() => {
    active && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  }, []);

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsActive(true);
  }

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsActive(false);
  }

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  }

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }

  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );

  return (
    <header {...props} className={classes}>
      <div className="container">
        <div className={classNames('site-header-inner', bottomDivider && 'has-bottom-divider')}>
          <Logo />
          {!hideNav && (
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={() => {
                  handleButtonClick('menu_toggle');
                  isActive ? closeMenu() : openMenu();
                }}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames(
                  'header-nav',
                  isActive && 'is-active',
                  invertColor && 'invert-color'
                )}
              >
                <div className="header-nav-inner">
                  <ul className={classNames('list-reset text-xs header-nav-links', navPosition && `header-nav-${navPosition}`)}>
                    <li><Link to="/hiring/" onClick={() => { closeMenu(); handleButtonClick('mentor_hiring_click'); }}>Mentor Hiring</Link></li>
                    <li><Link to="/testimonials/" onClick={() => { closeMenu(); handleButtonClick('testimonials_click'); }}>Testimonials</Link></li>
                    <li><Link to="/research/" onClick={() => { closeMenu(); handleButtonClick('research_click'); }}>Research</Link></li>
                    <li><Link to="/bootcamp/" onClick={() => { closeMenu(); handleButtonClick('bootcamp_click'); }}>Bootcamp</Link></li>
                  </ul>
                  {!hideSignin && (
                    <ul className="list-reset header-nav-right">
                      <li className="nav-button-container w-full flex justify-center">
                        <Button 
                          tag="a" 
                          color="primary" 
                          wideMobile 
                          href="/apply"
                          onClick={() => handleButtonClick('apply_click')}
                        >
                          Apply now
                        </Button>
                      </li>
                    </ul>
                  )}
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;