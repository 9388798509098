import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const StanfordMentorship = () => {
  const pageTitle = "Stanford AI Mentorship Program | Expert Research Guidance";
  const pageDescription = "Stanford mentored AI program with 1-on-1 guidance. Learn machine learning, neural networks, deep learning from Stanford researchers. Personalized project mentorship and research support.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Stanford AI Mentorship Program",
    "description": pageDescription,
    "provider": {
      "@type": "Organization",
      "name": "BLAST AI",
      "sameAs": "https://blastai.org"
    },
    "courseCode": "BLAST-STANFORD-2025",
    "educationalLevel": "High School",
    "hasCourseInstance": {
      "@type": "CourseInstance",
      "courseMode": "online",
      "duration": "P8W",
      "startDate": "2025-06-17",
      "endDate": "2025-08-12",
      "price": 1460,
      "priceCurrency": "USD"
    },
    "teaches": [
      "Artificial Intelligence",
      "Machine Learning",
      "Deep Learning",
      "Neural Networks",
      "Computer Vision",
      "Natural Language Processing",
      "Research Methodology",
      "Project Development"
    ]
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* OpenGraph tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blastai.org/stanford-mentored-ai-program" />
        <meta property="og:image" content="https://blastai.org/stanford-mentorship.jpg" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        
        {/* Keywords and SEO */}
        <meta name="keywords" content="stanford ai mentorship, stanford ml program, ai research mentoring, machine learning guidance, stanford researchers, ai project mentorship, stanford mentored program, high school ai program, stanford ai training, ml research support" />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large" />
        <link rel="canonical" href="https://blastai.org/stanford-mentored-ai-program" />

        {/* Add CSS for visually hidden elements */}
        <style>
          {`
            .visually-hidden {
              position: absolute;
              width: 1px;
              height: 1px;
              padding: 0;
              margin: -1px;
              overflow: hidden;
              clip: rect(0, 0, 0, 0);
              white-space: nowrap;
              border: 0;
            }
          `}
        </style>

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="landing-page">
        <h1 className="visually-hidden">{pageTitle}</h1>
        <Research />
      </div>
    </>
  );
};

export default StanfordMentorship; 