import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/LogoInvert';
import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';
import Cta from '../sections/Cta';
import { Link } from 'react-router-dom';
import VisuallyHidden from '../elements/VisuallyHidden';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {
  const classes = classNames(
    'site-footer center-content-mobile bg-gradient-to-t from-blue-400 to-white',
    topOuterDivider && 'has-top-divider',
    className
  );

  return (
    <footer {...props} className={classes}>
      <div className="container">
        <Cta hasBgColor invertColor split id="apply"/>
        <div className={classNames('site-footer-inner', topDivider && 'has-top-divider')}>
          <div className="footer-top space-between text-xxs">
            <Logo />
            <FooterSocial />
          </div>
          <div className="footer-bottom space-between text-xxs invert-order-desktop">
            <FooterNav />
            <div className="footer-copyright">&copy; 2025 BLAST AI, all rights reserved</div>
          </div>
        </div>
      </div>
      {/* SEO Navigation Links */}
      <VisuallyHidden as="nav" aria-label="SEO Navigation">
        <h2 className="visually-hidden">Additional Navigation</h2>
        <ul>
          <li><a href="https://blastai.com/">Home</a></li>
          <li><a href="https://blastai.com/bootcamp">Bootcamp</a></li>
          <li><a href="https://blastai.com/research">Research</a></li>
          <li><a href="https://blastai.com/testimonials">Testimonials</a></li>
          <li><a href="https://blastai.com/team">Team</a></li>
          <li><a href="https://blastai.com/apply-research">Apply Research</a></li>
          <li><a href="https://blastai.com/apply">Apply</a></li>
          <li><a href="https://blastai.com/ai-summer-program">AI Summer Program</a></li>
          <li><a href="https://blastai.com/high-school-ai-program">High School AI Program</a></li>
          <li><a href="https://blastai.com/ai-research-program">AI Research Program</a></li>
          <li><a href="https://blastai.com/ai-bootcamp">AI Bootcamp</a></li>
          <li><a href="https://blastai.com/summer-ai-course">Summer AI Course</a></li>
          <li><a href="https://blastai.com/ai-program-high-school">AI Program High School</a></li>
          <li><a href="https://blastai.com/machine-learning-program">Machine Learning Program</a></li>
          <li><a href="https://blastai.com/artificial-intelligence-course">Artificial Intelligence Course</a></li>
          <li><a href="https://blastai.com/ai-summer-camp">AI Summer Camp</a></li>
          <li><a href="https://blastai.com/summer-ai-camp">Summer AI Camp</a></li>
          <li><a href="https://blastai.com/ai-camp">AI Camp</a></li>
          <li><a href="https://blastai.com/ai-camp-high-school">AI Camp High School</a></li>
          <li><a href="https://blastai.com/ai-research-opportunities">AI Research Opportunities</a></li>
          <li><a href="https://blastai.com/high-school-research">High School Research</a></li>
          <li><a href="https://blastai.com/high-school-research-opportunities">High School Research Opportunities</a></li>
          <li><a href="https://blastai.com/research-opportunities-high-school">Research Opportunities High School</a></li>
          <li><a href="https://blastai.com/student-research-opportunities">Student Research Opportunities</a></li>
          <li><a href="https://blastai.com/research-programs-high-school">Research Programs High School</a></li>
          <li><a href="https://blastai.com/high-school-research-programs">High School Research Programs</a></li>
          <li><a href="https://blastai.com/research-opportunities-for-high-school-students">Research Opportunities For High School Students</a></li>
          <li><a href="https://blastai.com/high-school-student-research">High School Student Research</a></li>
          <li><a href="https://blastai.com/research-paper-guide">Research Paper Guide</a></li>
          <li><a href="https://blastai.com/machine-learning-internships">Machine Learning Internships</a></li>
          <li><a href="https://blastai.com/apple-machine-learning-internship">Apple Machine Learning Internship</a></li>
          <li><a href="https://blastai.com/machine-learning-engineer-internships">Machine Learning Engineer Internships</a></li>
          <li><a href="https://blastai.com/blog/internships">Internships Blog</a></li>
          <li><a href="https://blastai.com/ai-summer-school">AI Summer School</a></li>
          <li><a href="https://blastai.com/deep-learning-summer-course">Deep Learning Summer Course</a></li>
          <li><a href="https://blastai.com/machine-learning-research-opportunities">Machine Learning Research Opportunities</a></li>
          <li><a href="https://blastai.com/ai-hackathon-high-school">AI Hackathon High School</a></li>
          <li><a href="https://blastai.com/data-science-summer-institute">Data Science Summer Institute</a></li>
          <li><a href="https://blastai.com/stanford-mentored-ai-program">Stanford Mentored AI Program</a></li>
          <li><a href="https://blastai.com/high-school-ai-camp">High School AI Camp</a></li>
          <li><a href="https://blastai.com/high-school-artificial-intelligence-camp">High School Artificial Intelligence Camp</a></li>
          <li><a href="https://blastai.com/teen-ai-camp">Teen AI Camp</a></li>
          <li><a href="https://blastai.com/ai-camp-for-teens">AI Camp For Teens</a></li>
          <li><a href="https://blastai.com/artificial-intelligence-camp-high-school">Artificial Intelligence Camp High School</a></li>
          <li><a href="https://blastai.com/block-blast-ai">Block BLAST AI</a></li>
          <li><a href="https://blastai.com/visual-ai-programming">Visual AI Programming</a></li>
          <li><a href="https://blastai.com/block-coding-ai">Block Coding AI</a></li>
          <li><a href="https://blastai.com/block-based-ai">Block Based AI</a></li>
          <li><a href="https://blastai.com/visual-ai-learning">Visual AI Learning</a></li>
          <li><a href="https://blastai.com/block-ai-platform">Block AI Platform</a></li>
          <li><a href="https://blastai.com/ai-research-programs-high-school">AI Research Programs High School</a></li>
          <li><a href="https://blastai.com/high-school-ai-research">High School AI Research</a></li>
          <li><a href="https://blastai.com/ai-research-opportunities-students">AI Research Opportunities Students</a></li>
          <li><a href="https://blastai.com/student-ai-research-programs">Student AI Research Programs</a></li>
          <li><a href="https://blastai.com/high-school-ai-research-opportunities">High School AI Research Opportunities</a></li>
          <li><a href="https://blastai.com/ai-research-for-high-school-students">AI Research For High School Students</a></li>
          <li><a href="https://blastai.com/machine-learning-high-school-camp">Machine Learning High School Camp</a></li>
          <li><a href="https://blastai.com/ml-camp-high-school">ML Camp High School</a></li>
          <li><a href="https://blastai.com/machine-learning-camp-teens">Machine Learning Camp Teens</a></li>
          <li><a href="https://blastai.com/high-school-ml-program">High School ML Program</a></li>
          <li><a href="https://blastai.com/teen-machine-learning-camp">Teen Machine Learning Camp</a></li>
          <li><a href="https://blastai.com/ml-summer-program-high-school">ML Summer Program High School</a></li>
          <li><a href="https://blastai.com/ai-summer-program-high-school">AI Summer Program High School</a></li>
          <li><a href="https://blastai.com/high-school-ai-summer-program">High School AI Summer Program</a></li>
          <li><a href="https://blastai.com/summer-ai-program-teens">Summer AI Program Teens</a></li>
          <li><a href="https://blastai.com/ai-program-high-school-summer">AI Program High School Summer</a></li>
          <li><a href="https://blastai.com/teen-ai-summer-program">Teen AI Summer Program</a></li>
          <li><a href="https://blastai.com/teen-ai-academy">Teen AI Academy</a></li>
          <li><a href="https://blastai.com/ai-academy-teens">AI Academy Teens</a></li>
          <li><a href="https://blastai.com/teen-artificial-intelligence-academy">Teen Artificial Intelligence Academy</a></li>
          <li><a href="https://blastai.com/youth-ai-academy">Youth AI Academy</a></li>
          <li><a href="https://blastai.com/ai-academy-young-learners">AI Academy Young Learners</a></li>
          <li><a href="https://blastai.com/deep-learning-high-school">Deep Learning High School</a></li>
          <li><a href="https://blastai.com/high-school-deep-learning-program">High School Deep Learning Program</a></li>
          <li><a href="https://blastai.com/neural-networks-high-school">Neural Networks High School</a></li>
          <li><a href="https://blastai.com/deep-learning-summer-high-school">Deep Learning Summer High School</a></li>
          <li><a href="https://blastai.com/teen-deep-learning-program">Teen Deep Learning Program</a></li>
          <li><a href="https://blastai.com/youth-ai-institute">Youth AI Institute</a></li>
          <li><a href="https://blastai.com/youth-artificial-intelligence-institute">Youth Artificial Intelligence Institute</a></li>
          <li><a href="https://blastai.com/ai-institute-young-learners">AI Institute Young Learners</a></li>
          <li><a href="https://blastai.com/teen-ai-institute">Teen AI Institute</a></li>
          <li><a href="https://blastai.com/student-ai-institute">Student AI Institute</a></li>
        </ul>
      </VisuallyHidden>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;