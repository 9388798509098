import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../components/layout/Banner';

const LayoutPlain = ({ children, canonicalUrl }) => (
  <>
    <Helmet>
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
    </Helmet>
    <Banner />
    <main className="site-content pt-8">
      {children}
    </main>
  </>
);

export default LayoutPlain;  