import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const HighSchoolAICamp = () => {
  const pageTitle = "AI Summer Camp for High School Students | BlastAI 2025";
  const pageDescription = "Join our immersive AI summer camp where high school students learn AI fundamentals, build real projects, and get mentored by Stanford experts. Start your AI journey today.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "EducationalEvent",
    "name": "High School AI Camp 2025",
    "description": pageDescription,
    "startDate": "2025-06-17",
    "endDate": "2025-08-12",
    "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
    "eventStatus": "https://schema.org/EventScheduled",
    "organizer": {
      "@type": "Organization",
      "url": "https://blastai.org"
    },
    "offers": {
      "@type": "Offer",
      "price": "1460",
      "priceCurrency": "USD",
      "availability": "https://schema.org/InStock",
      "validFrom": "2024-02-01"
    },
    "teaches": [
      "High School AI Curriculum",
      "AI Project Development",
      "Machine Learning for Teens",
      "Python Programming",
      "Neural Networks",
      "Computer Vision",
      "Natural Language Processing"
    ]
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* OpenGraph tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blastai.org/high-school-ai-camp" />
        <meta property="og:image" content="https://blastai.org/blast-ai-logo.png" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="High School AI Camp 2025 | Learn AI This Summer" />
        <meta name="twitter:description" content="The perfect AI camp for high school students. Build AI projects and learn from Stanford mentors at our high school artificial intelligence camp." />
        
        {/* Keywords heavily targeting high school AI camp */}
        <meta name="keywords" content="high school ai camp, ai camp for high school students, high school artificial intelligence camp, ai summer camp for teens, high school ai program, artificial intelligence camp for high school, teen ai camp, ai camp for teenagers, high school ai summer program, ai education for high school, high school machine learning camp, ai programming camp teens, high school robotics ai camp" />
        
        {/* Additional SEO tags */}
        <meta name="author" content="Stanford & MIT Mentors" />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large" />
        <link rel="canonical" href="https://blastai.org/high-school-ai-camp" />

        {/* FAQ Schema */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "What do high school students learn at AI camp?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "High school students learn AI fundamentals, build AI projects, work with neural networks, and develop machine learning applications with guidance from Stanford mentors."
                }
              },
              {
                "@type": "Question",
                "name": "Is this AI camp suitable for high school students?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, our AI camp is specifically designed for high school students, with curriculum and projects tailored for teens interested in artificial intelligence."
                }
              }
            ]
          })}
        </script>

        {/* Course Schema */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      
      <main>
        <h1>AI Summer Camp for High School Students - BlastAI 2025</h1>
        <Research />
      </main>
    </>
  );
};

export default HighSchoolAICamp; 