import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSplit from '../../components/sections/HeroSplit';
import FeaturesSplit from '../../components/sections/FeaturesSplit';
import Testimonial from '../../components/sections/Testimonial';
import FAQ from '../../components/sections/FAQ';
import Image from '../../components/elements/Image';

const pageTitle = "High School AI Research Program | Expert Mentorship | Polygence";
const pageDescription = "Transform into an AI researcher through 1-on-1 mentorship with Stanford and MIT experts. Create original research, publish papers, and present at conferences - all in high school.";

const structuredData = {
  "@context": "https://schema.org",
  "@type": "EducationalService",
  "name": "Polygence High School Research Program",
  "description": pageDescription,
  "provider": {
    "@type": "Organization",
    "name": "Polygence"
  }
};

class HighSchoolResearch extends React.Component {
  render() {
    return (
      <main className="high-school-research-page">
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>

        <header>
          <div className="container-xl">
            <div className="hero-content">
              <h1 className="mt-20 mb-16 reveal-from-bottom h2-size" data-reveal-delay="200">
                High School AI Research Program: Learn from Stanford & MIT Experts
              </h1>
              <div className="container-lg">
                <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  Join our research program and work with Stanford and MIT mentors on cutting-edge AI projects. Publish papers and present at international conferences while still in high school.
                </p>
              </div>
            </div>
          </div>
        </header>
        
        <article>
          <div className="container-xl">
            <div className="features-split-inner section-inner has-top-divider mt-10">
              <div className="split-wrap">
                
                <div className="split-item">
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Research Areas</h3>
                    <p className="m-0">
                      Work on cutting-edge AI research:
                      <ul>
                        <li>Deep Learning and Neural Networks</li>
                        <li>Natural Language Processing</li>
                        <li>Computer Vision Research</li>
                        <li>Reinforcement Learning</li>
                        <li>AI for Medical Applications</li>
                      </ul>
                    </p>
                  </div>
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/molecular.jpg`}
                      alt="Research Areas"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                </div>

                <div className="split-item">
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/urtc_lecture.jpg`}
                      alt="Program Structure"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Program Structure</h3>
                    <p className="m-0">
                      Our 8-week intensive program includes:
                      <ul>
                        <li>1-on-1 Mentorship with Stanford/MIT researchers</li>
                        <li>Weekly research workshops and seminars</li>
                        <li>Hands-on project development</li>
                        <li>Paper writing and publication support</li>
                        <li>Conference presentation preparation</li>
                      </ul>
                    </p>
                  </div>
                </div>

                <div className="split-item">
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Student Success Stories</h3>
                    <p className="m-0">
                      Our students achieve remarkable results:
                      <ul>
                        <li>Published research papers in peer-reviewed conferences</li>
                        <li>Presented at international AI symposiums</li>
                        <li>Won Kaggle competitions</li>
                        <li>Developed novel AI architectures</li>
                        <li>Secured admission to top universities</li>
                      </ul>
                    </p>
                  </div>
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/scale_paper.jpg`}
                      alt="Student Success"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                </div>

                <div className="split-item">
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/priors_paper.jpg`}
                      alt="College Admissions"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">College Admissions Advantage</h3>
                    <p className="m-0">
                      Stand out in college applications with:
                      <ul>
                        <li>Published research papers</li>
                        <li>Letters of recommendation from Stanford/MIT mentors</li>
                        <li>Demonstrated expertise in cutting-edge AI</li>
                        <li>Portfolio of advanced technical projects</li>
                        <li>Real-world research experience</li>
                      </ul>
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </article>
 
        <FAQ />
      </main>
    );
  }
}

export default HighSchoolResearch; 