import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className,
      'pt-4'
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Our approach to learning', // TODO: reword to be more positive!
      paragraph: "BLAST programs are designed to be exhilarating! With hands-on learning and individualized mentorship, our students design deep learning architectures for real-life applications."
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>


              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Hands-on learning philosophy
                  </h3>
                  <h4 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Our students learn from real-world challenges
                  </h4>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    BLAST AI brings AI to life with exciting projects. Through carefully crafted exercises, students develop a keen intuition for classical machine learning theory. Students master deep learning architecture design through collaborative experimentation, gaining invaluable practical experience. BLAST AI teaches students to combine creativity, teamwork, and innovation through hands-on learning, readying them for tomorrow's AI challenges.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={`${process.env.REACT_APP_CLOUDFLARE_URL}/nishkal-transformer-lecture.jpg`}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </div>
              </div>
              <div className="split-item">
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={`${process.env.REACT_APP_CLOUDFLARE_URL}/calista-research-presentation.jpg`}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Our commitment to results
                  </h3>
                  <h4 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    BLAST students work on cutting-edge projects
                  </h4>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    BLAST AI students aren't just learning; they're innovating, with nearly 20 projects accepted or awaiting approval at PhD-level conferences. Our students have placed in the top 1% of Kaggle competitions, set new benchmarks for AI DOM accessibility, and even surpassing OpenAI embedding models in code alignment. With BLAST AI, you're not just studying AI, you're shaping the future of the field. Visit the research page to learn more!
                  </p>
                </div>
              </div>
              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Our focus on community
                  </h3>
                  <h4 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    BLAST students form a tight-knit network
                  </h4>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                  BLAST AI is more than just an instructional program; it's a vibrant community hub.  Our interactive workshops and fun community events transform online learning into a dynamic, social experience. Here, students form lasting connections, sharing insights and sparking ideas that lead to collaboration well beyond the program. Join BLAST AI and be part of a community that thrives on creativity, support, and the collective pursuit of life-long learning.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={`${process.env.REACT_APP_CLOUDFLARE_URL}/bootcamp.jpg`}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </div>
              </div>

              {/* <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Data-driven insights
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={`${process.env.REACT_APP_CLOUDFLARE_URL}/features-split-image-03.jpg`}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
              </div> */}

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;