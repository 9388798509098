import React from 'react';
import { useAnalytics } from '../../hooks/useAnalytics';

const Banner = () => {
  const { trackEvent } = useAnalytics();

  const handleApplyClick = () => {
      trackEvent('apply_click', {
          button_location: 'banner',
          page_path: window.location.pathname
      });
  };

  return (
    <div className="banner bg-blue-500 text-white p-4 text-center">
      <span style={{ fontSize: '0.75rem' }}>Early round applications to the 2025 summer program close on February 28, 2025.  </span>
      <a style={{ fontSize: '0.75rem', color: '#ffffff' }} href="/apply" className="ml-2 underline" onClick={handleApplyClick}>Apply Here</a>
    </div>
  );
};

export default Banner;