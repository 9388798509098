import React from 'react';
import Classes from '../components/sections/Classes';
import Board from '../components/sections/Board';
import Roadmap from '../components/sections/Roadmap';
import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta';
import TeamDesc from '../components/sections/TeamDesc';
import FAQ from '../components/sections/FAQ';
import Button from '../components/elements/Button';
import SectionHeader from '../components/sections/partials/SectionHeader';
import ApplicationTimeline from '../components/sections/ApplicationTimeline';
import FeaturesTabs from '../components/sections/FeaturesTabs';


class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <Classes  className="has-bg-color-cut illustration-section-02"/> */}
        <TeamDesc hasBgColor invertColor/>
        <Board />
        <FAQ />
      </React.Fragment>
    );
  }
}

export default Home;