import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
        <Link to="/">
          <Image
            src={`${process.env.REACT_APP_CLOUDFLARE_URL}/blastailogo.png`}
            alt="BLAST AI"
            width={200}
            height={80} />
        </Link>
      </h1>
    </div>
  );
}

export default Logo;