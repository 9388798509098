import React from 'react';
import { Helmet } from 'react-helmet-async';

class ResearchApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iframeHeight: 539 // Default height
    };
  }

  componentDidMount() {
    // Place your script here
    
    var ifr = document.getElementById("JotFormIFrame-243627268557165");
    if (ifr) {
      var src = ifr.src;
      var iframeParams = [];
      if (window.location.href && window.location.href.indexOf("?") > -1) {
        iframeParams = iframeParams.concat(window.location.href.substr(window.location.href.indexOf("?") + 1).split('&'));
      }
      if (src && src.indexOf("?") > -1) {
        iframeParams = iframeParams.concat(src.substr(src.indexOf("?") + 1).split("&"));
        src = src.substr(0, src.indexOf("?"))
      }
      iframeParams.push("isIframeEmbed=1");
      ifr.src = src + "?" + iframeParams.join('&');
    }

    window.handleIFrameMessage = (e) => {
      if (typeof e.data === 'object') { return; }
      var args = e.data.split(":");
      var iframe;
      if (args.length > 2) { iframe = document.getElementById("JotFormIFrame-" + args[(args.length - 1)]); } else { iframe = document.getElementById("JotFormIFrame"); }
      if (!iframe) { return; }
      switch (args[0]) {
        case "scrollIntoView":
          iframe.scrollIntoView();
          break;
        case "setHeight":
          iframe.style.height = args[1] + "px";
          if (!isNaN(args[1]) && parseInt(iframe.style.minHeight) > parseInt(args[1])) {
            iframe.style.minHeight = args[1] + "px";
          }
          // Update state variable
          this.setState({ iframeHeight: args[1] });
          break;
        case "collapseErrorPage":
          if (iframe.clientHeight > window.innerHeight) {
            iframe.style.height = window.innerHeight + "px";
          }
          break;
        case "reloadPage":
          window.location.reload();
          break;
        case "loadScript":
          if( !window.isPermitted(e.origin, ['jotform.com', 'jotform.pro']) ) { break; }
          var src = args[1];
          if (args.length > 3) {
              src = args[1] + ':' + args[2];
          }
          var script = document.createElement('script');
          script.src = src;
          script.type = 'text/javascript';
          document.body.appendChild(script);
          break;
        case "exitFullscreen":
          if      (window.document.exitFullscreen)        window.document.exitFullscreen();
          else if (window.document.mozCancelFullScreen)   window.document.mozCancelFullScreen();
          else if (window.document.mozCancelFullscreen)   window.document.mozCancelFullScreen();
          else if (window.document.webkitExitFullscreen)  window.document.webkitExitFullscreen();
          else if (window.document.msExitFullscreen)      window.document.msExitFullscreen();
          break;
        default:
          break;
      }
    };

    window.isPermitted = function(originUrl, whitelisted_domains) {
      var url = document.createElement('a');
      url.href = originUrl;
      var hostname = url.hostname;
      var result = false;
      if( typeof hostname !== 'undefined' ) {
        whitelisted_domains.forEach(function(element) {
            if( hostname.slice((-1 * element.length - 1)) === '.'.concat(element) ||  hostname === element ) {
                result = true;
            }
        });
        return result;
      }
    };

    if (window.addEventListener) {
      window.addEventListener("message", window.handleIFrameMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", window.handleIFrameMessage);
    }
  }

  render() {
    const hideBottomPx = 64;  // Number of pixels to hide from the bottom
    const containerHeight = this.state.iframeHeight - hideBottomPx;

    const pageTitle = "Apply to High School AI Program | Research & Machine Learning | BLAST AI";
    const pageDescription = "Apply to high school AI research program. Stanford mentored machine learning projects, AI summer program 2025, research opportunities for high school students. Applications open now.";

    const structuredData = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": pageTitle,
      "description": pageDescription,
      "provider": {
        "@type": "Organization",
        "name": "BLAST AI",
        "url": "https://blastai.org"
      },
      "mainEntity": {
        "@type": "EducationalProgram",
        "name": "BLAST AI Research Program",
        "applicationDeadline": "2025-04-30",
        "educationalProgramMode": "online",
        "timeOfYear": "Summer",
        "educationalLevel": "High School"
      }
    };

    return (
      <React.Fragment>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          
          {/* OpenGraph tags */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://blastai.org/apply" />
          <meta property="og:image" content="https://blastai.org/blast-ai-logo.png" />
          <meta property="og:site_name" content="BLAST AI" />
          
          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
          <meta name="twitter:image" content="https://blastai.org/blast-ai-logo.png" />
          
          {/* Additional SEO tags */}
          <meta name="keywords" content="ai research application, high school research program, stanford mentorship application, mit research program, ai research opportunities, machine learning research, high school ai program application" />
          <meta name="author" content="BLAST AI" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://blastai.org/apply" />
          
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
        <div style={{ height: `${containerHeight}px`, overflow: 'hidden' }}>
          <iframe
            id="JotFormIFrame-243627268557165"
            title="2024 BLAST AI Summer Research Cohort Application"
            onload="window.parent.scrollTo(0,0)"
            allowtransparency="true"
            allowfullscreen="true"
            allow="geolocation; microphone; camera"
            src="https://form.jotform.com/243627268557165"
            frameBorder="0"
            style={{ minWidth: '100%', maxWidth: '100%', height: `${this.state.iframeHeight}px`, border: 'none' }}
            scrolling="no"
          ></iframe>
        </div>
      </React.Fragment>
    );
  }
}

export default ResearchApplication;
