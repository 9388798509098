import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../../utils/SectionProps';
import SectionHeader from '../partials/SectionHeader';
import Image from '../../elements/Image';
import Button from '../../elements/Button';
import VisuallyHidden from '../../elements/VisuallyHidden';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Bootcamp Curriculum',
      paragraph: "The first two weeks of the program are spent in a machine learning bootcamp. Learn the fundamentals of neural networks and apply ML theory to a real-world project."
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <h1>
              <VisuallyHidden>BLAST AI Bootcamp - Learn Machine Learning and AI Development High School Students</VisuallyHidden>
            </h1>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>

              {/* <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Research Program Details
                  </h3>
                  <h4 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    How BLAST Research works
                  </h4>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    During the 12-week program, you'll work with a Principal Investigator and team of like-minded students to research a predtermined novel idea. 
                    BLAST is <b>fully virtual</b>, and our fall programs will run <b>part-time</b> alongside school and clubs – regardless of where you are in the world. 
                    However, research is<i> extremeley </i>hard, and we expect intensive commitment and effort.
                    With our original ideas and your hard work, your contributions to cutting edge-research are rewarded by top-tier publication.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={`${process.env.REACT_APP_CLOUDFLARE_URL}/belllecture.jpg`}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
              </div> */}

              {/* <div className="split-item">
                <div className="split-item-content center-content">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Program Details
                  </h3>
                  <h4 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    2-Week Project-Based AI Program
                  </h4>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    BLAST AI Launchpad is an introductory machine learning program for high school students. 
                    It's <b>fully virtual</b> and will take place during the following time periods: 7/1 to 7/12, 7/15 to 7/26, and 7/29 to 8/9. 
                    <br/>
                    <br/>
                    You will learn everything from python fundamentals to convolutional neural networks and transformers. 
                    By the end of the project, you will complete a personalized capstone project in your selected project track.
                  </p>
                  <div class="center-content py-8">
                    <Button tag="a" className="hero-button" color="primary" href="/apply-launchpad" wideMobile>Launchpad Application</Button>
                  </div>

                </div>
              </div> */}

              <div style={{ display: 'flex', justifyContent: 'center' }} className="mb-10">
                <Image
                  src={`${process.env.REACT_APP_CLOUDFLARE_URL}/bootcamp-schedule.png`}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>

              {/* <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Data-driven insights
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={`${process.env.REACT_APP_CLOUDFLARE_URL}/features-split-image-03.jpg`}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
              </div> */}

            </div>
            {/* <ButtonGroup>
                    <Button >
                      Read Student Publications
                    </Button>                    
                  </ButtonGroup> */}
            
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;