import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from '../../components/sections/partials/SectionHeader';
import Image from '../../components/elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class InternshipBlog extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'news section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'news-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Machine Learning Internship Resources',
      paragraph: 'Comprehensive guides and insights about machine learning internships, from application tips to company-specific opportunities.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom mt-10" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <figure className="news-item-image m-0">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/nishkal-transformer-lecture.jpg`}
                      alt="Apple Machine Learning Internship Opportunities and Application Guide"
                      width={344}
                      height={194}
                      style={{objectFit: 'cover'}} />
                  </figure>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      <h3 className="news-item-title h4 mt-0 mb-8">
                        <a href="/apple-machine-learning-internship">Apple Machine Learning Internship Guide 2024</a>
                      </h3>
                      <p className="mb-16 text-sm">
                        A comprehensive guide to securing a machine learning internship at Apple. Learn about the application process, required skills, and tips from successful candidates.
                      </p>
                    </div>
                    <div className="news-item-more text-xs mb-8">
                      <a href="/apple-machine-learning-internship">Read more</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner has-shadow">
                  <figure className="news-item-image m-0">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/calista-research-presentation.jpg`}
                      alt="Machine Learning Engineer Internship Opportunities and Career Guide"
                      width={344}
                      height={194}
                      style={{objectFit: 'cover'}} />
                  </figure>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      <h3 className="news-item-title h4 mt-0 mb-8">
                        <a href="/machine-learning-engineer-internships">Top ML Engineer Internships for 2024</a>
                      </h3>
                      <p className="mb-16 text-sm">
                        Explore the best machine learning engineer internship opportunities at top tech companies. Get insights on requirements, application timelines, and preparation strategies.
                      </p>
                    </div>
                    <div className="news-item-more text-xs mb-8">
                      <a href="/machine-learning-engineer-internships">Read more</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="tiles-item-inner has-shadow">
                  <figure className="news-item-image m-0">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/bootcamp.jpg`}
                      alt="Machine Learning Internship Programs and Opportunities"
                      width={344}
                      height={194}
                      style={{objectFit: 'cover'}} />
                  </figure>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      <h3 className="news-item-title h4 mt-0 mb-8">
                        <a href="/machine-learning-internships">Complete ML Internship Guide</a>
                      </h3>
                      <p className="mb-16 text-sm">
                        Everything you need to know about machine learning internships - from preparation and skills needed to top companies hiring ML interns.
                      </p>
                    </div>
                    <div className="news-item-more text-xs mb-8">
                      <a href="/machine-learning-internships">Read more</a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

InternshipBlog.propTypes = propTypes;
InternshipBlog.defaultProps = defaultProps;

export default InternshipBlog; 