import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../../utils/SectionProps';
import SectionHeader from '../partials/SectionHeader';
import Image from '../../elements/Image';

// import YouTube, { YouTubeProps } from 'react-youtube';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Testimonial extends React.Component {

  render() {
    const {
      className,
      topDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'testimonial section',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
    );

    const tilesClasses = classNames(
      'tiles-wrap-2',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Publications',
      paragraph: 'BLAST AI is proud to present the publications and presentations of students from previous research cohorts.'
      // paragraph: 'A part-time online program for talented individuals across the world. During the 12-week program, you will conduct real-world, original AI research. BLAST research is impactful: in the 2023 summer cohort, we beat OpenAI at code alignment, lead the industry in galaxy anomaly detection, and invented embedding algorithms and and loss functions for LLMs.',
      // paragraph2: ' \n NOTE: BLAST Research is designed for students who have extensive experience with ML, especially those who have completed our Bootcamp.'
    };
  
    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container-lg text-gray-500">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              <div className="tiles-item   "  >
                  <div className="tiles-item-inner has-shadow">
                    <div className="testimonial-item-header mb-16">
                      <div className="testimonial-item-image">
                        <Image
                            src={`${process.env.REACT_APP_CLOUDFLARE_URL}/arxiv.jpg`}
                            alt="Testimonial 03"
                            width={90}
                            />
                      </div>
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                      Unsupervised Learning of Molecular Embeddings for Enhanced Clustering and Emergent Property Detection in Chemical Compounds
                      </p>
                      <br/>
                      <p className="text-sm mb-0">* Awaiting review</p>
                    </div>
                    <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-link fw-600">
                        <a target="_blank" href="https://www.youtube.com/watch?v=i5OJwdvQM6w">Watch presentation</a>
                      </span>
                    </div>
                  </div>
                </div>
              <div className="tiles-item">
                  <div className="tiles-item-inner has-shadow">
                    <div className="testimonial-item-header mb-16">
                      <div className="testimonial-item-image">
                        <Image
                            src={`${process.env.REACT_APP_CLOUDFLARE_URL}/arxiv.jpg`}
                            alt="Testimonial 03"
                            height={60} 
                            width={100}
                            />
                      </div>
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                        SCALE: Semantic Code Analysis via Learned Embeddings
                      </p>
                      <br/>
                      <p className="text-sm mb-0">* Awaiting review</p>
                    </div>
                    <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-link fw-600">
                        <a target="_blank" href="https://www.youtube.com/watch?v=HwN21tFi9jI">Watch presentation</a>
                      </span>
                    </div>
                  </div>
                </div>
              <div className="tiles-item   "  >
                  <div className="tiles-item-inner has-shadow">
                    <div className="testimonial-item-header mb-16">
                      <div className="testimonial-item-image">
                        <Image
                            src={`${process.env.REACT_APP_CLOUDFLARE_URL}/IEEE-Logo.jpg`}
                            alt="Testimonial 03"
                            height={48} 
                            width={120}
                            />
                      </div>
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                      AuPPLE: Augmenting Physical Priors through Language Enhancement using Self-Supervised Learning
                      </p>
                    </div>
                    <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-link fw-600">
                        <a target="_blank" href="https://www.youtube.com/watch?v=9QylugLT6eA&t=394s">Watch presentation</a>
                      </span>
                    </div>
                  </div>
                </div>
              <div className="tiles-item"  >
                  <div className="tiles-item-inner has-shadow">
                    <div className="testimonial-item-header mb-16">
                      <div className="testimonial-item-image">
                        <Image
                            src={`${process.env.REACT_APP_CLOUDFLARE_URL}/royal_astro.png`}
                            alt="Testimonial 03"
                            height={75} 
                            width={75}
                            />
                      </div>
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                      GANaxy: Galaxy Image Anomaly Detection with Contrasative learning and DCGANs
                      </p>
                      <br/>
                      <p className="text-sm mb-0">* Awaiting review</p>
                    </div>
                    <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-link fw-600">
                        <a target="_blank" href="https://www.youtube.com/watch?v=6GR1EVvtbeY">Watch presentation</a>
                      </span>
                    </div>
                  </div>
                </div>
              <div className="tiles-item   "  >
                  <div className="tiles-item-inner has-shadow">
                    <div className="testimonial-item-header mb-16">
                      <div className="testimonial-item-image">
                        <Image
                            src={`${process.env.REACT_APP_CLOUDFLARE_URL}/IEEE-Logo.jpg`}
                            alt="Testimonial 03"
                            height={48} 
                            width={120}
                            />
                      </div>
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                      DataAgent: Evaluating Large Language Models’ Ability to Answer Zero-Shot, Natural Language Queries
                      </p>
                      <br/>
                      <p className="text-sm mb-0">* Awaiting review</p>
                    </div>
                    <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-link fw-600">
                        <a target="_blank" href="https://www.youtube.com/watch?v=73okpNSEN3o">Watch presentation</a>
                      </span>
                    </div>
                  </div>
                </div>
              <div className="tiles-item   "  >
                  <div className="tiles-item-inner has-shadow">
                    <div className="testimonial-item-header mb-16">
                      <div className="testimonial-item-image">
                        <Image
                            src={`${process.env.REACT_APP_CLOUDFLARE_URL}/IEEE-Logo.jpg`}
                            alt="Testimonial 03"
                            height={48} 
                            width={120}
                            />
                      </div>
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                      Generating Synthetic Malware Samples using GANs
                      </p>
                    </div>
                    <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-link fw-600">
                        <a target="_blank" href="https://www.youtube.com/watch?v=cxWDPoURisE">Watch presentation</a>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="tiles-item   "  >
                  <div className="tiles-item-inner has-shadow">
                    <div className="testimonial-item-header mb-16">
                      <div className="testimonial-item-image">
                        <Image
                            src={`${process.env.REACT_APP_CLOUDFLARE_URL}/aaai.jpg`}
                            alt="Testimonial 03"
                            height={60} 
                            width={60}
                            />
                      </div>
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                      Image to Image Diffusion for Underwater Image Enhancement
                      </p>
                      <br/>
                      <p className="text-sm mb-0">* Awaiting review</p>
                    </div>
                    <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-link fw-600">
                        <a target="_blank" href="https://www.youtube.com/watch?v=ErFpe0GLUm8">Watch presentation</a>
                      </span>
                    </div>
                  </div>
                </div>
              <div className="tiles-item   "  >
                  <div className="tiles-item-inner has-shadow">
                    <div className="testimonial-item-header mb-16">
                      <div className="testimonial-item-image">
                        <Image
                            src={`${process.env.REACT_APP_CLOUDFLARE_URL}/arxiv.jpg`}
                            alt="Testimonial 03"
                            // height={75} 
                            width={90}
                            />
                      </div>
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                      Quantifying Bias: A Benchmarking Approach for Large Language Models as Agents
                      </p>
                      <br/>
                      <p className="text-sm mb-0">* Awaiting review</p>
                    </div>
                    <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-link fw-600">
                        <a target="_blank" href="https://www.youtube.com/watch?v=VbJOEJ6ISaU">Watch presentation</a>
                      </span>
                    </div>
                  </div>
                </div>
              <div className="tiles-item   "  >
                  <div className="tiles-item-inner has-shadow">
                    <div className="testimonial-item-header mb-16">
                      <div className="testimonial-item-image">
                        <Image
                            src={`${process.env.REACT_APP_CLOUDFLARE_URL}/IEEE-Logo.jpg`}
                            alt="Testimonial 03"
                            height={48} 
                            width={120}
                            />
                      </div>
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                      Exploring Embeddings for Measuring Text Relatedness: Unveiling Sentiments and Relationships in Online Comments
                      </p>
                      <br/>
                      <p className="text-sm mb-0">* Awaiting review</p>
                    </div>
                    <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-link fw-600">
                        <a target="_blank" href="https://www.youtube.com/watch?v=769WCQz-HVk">Watch presentation</a>
                      </span>
                    </div>
                  </div>
                </div>
              <div className="tiles-item   "  >
                  <div className="tiles-item-inner has-shadow">
                    <div className="testimonial-item-header mb-16">
                      <div className="testimonial-item-image">
                        <Image
                            src={`${process.env.REACT_APP_CLOUDFLARE_URL}/IEEE-Logo.jpg`}
                            alt="Testimonial 03"
                            height={48} 
                            width={120}
                            />
                      </div>
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                      Skills from Large Language Model-guided Agents Yield Success
                      </p>
                      <br/>
                      <p className="text-sm mb-0">* Awaiting review</p>
                    </div>
                    <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-link fw-600">
                        <a target="_blank" href="https://www.youtube.com/watch?v=t-fKDOdZ0hk">Watch presentation</a>
                      </span>
                    </div>
                  </div>
                </div>
              <div className="tiles-item   "  >
                  <div className="tiles-item-inner has-shadow">
                    <div className="testimonial-item-header mb-16">
                      <div className="testimonial-item-image">
                        <Image
                            src={`${process.env.REACT_APP_CLOUDFLARE_URL}/IEEE-Logo.jpg`}
                            alt="Testimonial 03"
                            height={48} 
                            width={120}
                            />
                      </div>
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                      Deep Learning Approaches for Optimized Web Accessibility: Correcting Violations and Enhancing User Experience
                      </p>
                      <br/>
                      <p className="text-sm mb-0">* Awaiting review</p>
                    </div>
                    <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-link fw-600">
                        <a target="_blank" href="https://www.youtube.com/watch?v=wR9-2_YdVts">Watch presentation</a>
                      </span>
                    </div>
                  </div>
                </div>
              <div className="tiles-item   "  >
                  <div className="tiles-item-inner has-shadow">
                    <div className="testimonial-item-header mb-16">
                      <div className="testimonial-item-image">
                        <Image
                            src={`${process.env.REACT_APP_CLOUDFLARE_URL}/IEEE-Logo.jpg`}
                            alt="Testimonial 03"
                            height={48} 
                            width={120}
                            />
                      </div>
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                      From Pixels to Parity: Reducing Bias in Emotion Recognition
                      </p>
                      <br/>
                      <p className="text-sm mb-0">* Awaiting review</p>
                    </div>
                    <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-link fw-600">
                        <a target="_blank" href="https://www.youtube.com/watch?v=_OZLAe8M_ks">Watch presentation</a>
                      </span>
                    </div>
                  </div>
                </div>
              <div className="tiles-item   "  >
                  <div className="tiles-item-inner has-shadow">
                    <div className="testimonial-item-header mb-16">
                      <div className="testimonial-item-image">
                        <Image
                            src={`${process.env.REACT_APP_CLOUDFLARE_URL}/IEEE-Logo.jpg`}
                            alt="Testimonial 03"
                            height={48} 
                            width={120}
                            />
                      </div>
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                      Two Timin’: Repairing Smart Contracts With A Two-Layered Approach
                      </p>
                      <br/>
                      <p className="text-sm mb-0">* Awaiting review</p>
                    </div>
                    <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-link fw-600">
                        <a target="_blank" href="https://www.youtube.com/watch?v=S4IH_Uq3gno">Watch presentation</a>
                      </span>
                    </div>
                  </div>
                </div>
              <div className="tiles-item   "  >
                  <div className="tiles-item-inner has-shadow">
                    <div className="testimonial-item-header mb-16">
                      <div className="testimonial-item-image">
                        <Image
                            src={`${process.env.REACT_APP_CLOUDFLARE_URL}/IEEE-Logo.jpg`}
                            alt="Testimonial 03"
                            height={48} 
                            width={120}
                            />
                      </div>
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                      Analyzing Racial and Gender Bias Within Stable Diffusion Text-To-Image Models 
                      </p>
                      <br/>
                      <p className="text-sm mb-0">* Awaiting review</p>
                    </div>
                    <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-link fw-600">
                        <a target="_blank" href="https://www.youtube.com/watch?v=JDDlXbjPNhs">Watch presentation</a>
                      </span>
                    </div>
                  </div>
                </div>
              <div className="tiles-item   "  >
                  <div className="tiles-item-inner has-shadow">
                    <div className="testimonial-item-header mb-16">
                      <div className="testimonial-item-image">
                        <Image
                            src={`${process.env.REACT_APP_CLOUDFLARE_URL}/IEEE-Logo.jpg`}
                            alt="Testimonial 03"
                            height={48} 
                            width={120}
                            />
                      </div>
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                      Multi-Modal Approach to Predict Real-Estate Value
                      </p>
                      <br/>
                      <p className="text-sm mb-0">* Awaiting review</p>
                    </div>
                    <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-link fw-600">
                        <a target="_blank" href="https://www.youtube.com/watch?v=CC2U6eraKMw">Watch presentation</a>
                      </span>
                    </div>
                  </div>
                </div>
              <div className="tiles-item   " data-reveal-container=".tiles-wrap">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <div className="testimonial-item-image">
                    <Image
                        src={`${process.env.REACT_APP_CLOUDFLARE_URL}/IEEE-Logo.jpg`}
                        alt="Testimonial 03"
                        height={48} 
                        width={120}
                        />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      Using Transformers and Deep Learning with Stance Detection to Forecast Cryptocurrency Price Movement
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    {/* <span className="testimonial-item-name">
                      Seemab Y.
                    </span> */}
                    {/* <span className="text-color-low"> / </span> */}
                    <span className="testimonial-item-link fw-600">
                      <a target="_blank" href="https://ieeexplore.ieee.org/document/9953018">View paper</a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="tiles-item   "  >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <div className="testimonial-item-image">
                      <Image
                          src={`${process.env.REACT_APP_CLOUDFLARE_URL}/Aiaa_logo.png`}
                          alt="Testimonial 03"
                          height={48} 
                          width={120}
                          />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                    Utilizing Computer Vision Algorithms to Detect Contrails
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    {/* <span className="testimonial-item-name">
                      Anthony R.
                    </span> */}
                    {/* <span className="text-color-low"> / </span> */}
                    <span className="testimonial-item-link fw-600">
                      <a target="_blank" href="https://arc.aiaa.org/doi/abs/10.2514/6.2023-3769">View paper</a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="tiles-item   "  >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <div className="testimonial-item-image">
                    <Image
                        src={`${process.env.REACT_APP_CLOUDFLARE_URL}/IEEE-Logo.jpg`}
                        alt="Testimonial 03"
                        height={48} 
                        width={120}
                        />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                    Machine Learning in Clinical Text: Specialty Identification and COVID-19 Risk
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    {/* <span className="testimonial-item-name">
                      Nathan P.
                    </span> */}
                    {/* <span className="text-color-low"> / </span> */}
                    <span className="testimonial-item-link fw-600">
                      <a target="_blank" href="https://ieeexplore.ieee.org/document/9952512">View paper</a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="tiles-item   "  >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <div className="testimonial-item-image">
                    <Image
                        src={`${process.env.REACT_APP_CLOUDFLARE_URL}/IEEE-Logo.jpg`}
                        alt="Testimonial 03"
                        height={48} 
                        width={120}
                        />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      Stance Detection of Political Tweets with Transformer Architectures
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    {/* <span className="testimonial-item-name">
                      Nathan P.
                    </span> */}
                    {/* <span className="text-color-low"> / </span> */}
                    <span className="testimonial-item-link fw-600">
                      <a target="_blank" href="https://ieeexplore.ieee.org/document/9952951">View paper</a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="tiles-item"  >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <div className="testimonial-item-image">
                      <Image
                          src={`${process.env.REACT_APP_CLOUDFLARE_URL}/IEEE-Logo.jpg`}
                          alt="Testimonial 03"
                          height={48} 
                          width={120}
                          />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                    Genotype Imputation Using K-Nearest Neighbors and Levenshtein Distance Metric
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    {/* <span className="testimonial-item-name">
                      Anthony R.
                    </span> */}
                    {/* <span className="text-color-low"> / </span> */}
                    <span className="testimonial-item-link fw-600">
                      <a target="_blank" href="https://ieeexplore.ieee.org/document/9952611">View paper</a>
                    </span>
                  </div>
                </div>
              </div>


              {/* <div className="tiles-item   "  >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <div className="testimonial-item-image">
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                    Predicting Merger and Acquisition Deal Completion and Stock Movement with Stance Detection
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-link fw-600">
                      <a href="#">Pending publication</a>
                    </span>
                  </div>
                </div>
              </div> */}

            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
