import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className={splitClasses}>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Research Program Details
                  </h3>
                  <h4 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Transform from student to researcher
                  </h4>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    During the 8-week program, you'll work with a principal investigator and team of six to seven like-minded individuals to research a novel idea. BLAST is <b>fully virtual</b>, and we welcome talented students from across the world. 
                    <br/>
                    <br/>
                    During the program, you will complete a two-week ML bootcamp, write a 6-8 page paper, and present your work at the 2024 BLAST AI Summer Symposium. Additionally, expect to have plenty of fun during community events!
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={`${process.env.REACT_APP_CLOUDFLARE_URL}/cnn_cropped.gif`}
                    alt="Features split 01" />
                </div>
              </div>
              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    What we look for in applicants
                  </h3>
                  <h4 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    How to make your application stand out
                  </h4>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    While coding and ML experience is preferred, we primarily look for students with a track record of curiosity. 
                    In your application, mention the most interesting project you've worked on.
                    Highlight your passion for AI and provide examples of your determination.
                    If selected, you will be held to the same standards as a PhD student and will be expected to commit 20-30 hours per week.
                    Historically, our most dedicated students have landed top publications and received acceptance to prestigious universities.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={`${process.env.REACT_APP_CLOUDFLARE_URL}/application-tips.jpg`}
                    alt="Features split 01" 
                    style={{ width: '400px', height: '400px' }}
                    />
                </div>
              </div>
              <div className="split-item">
                
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Program Schedule
                  </h3>
                  <h4 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    8 Weeks: 06/17/2025 - 08/12/2025
                  </h4>
                  <ul>
                    <li>
                      <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                        Complete a 2-week ML bootcamp
                      </p>
                    </li>
                    <li>
                      <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                        Conduct literature review and complete abstract
                      </p>
                    </li>
                    <li>
                      <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                        Begin research and complete introduction section
                      </p>
                    </li>
                    <li>
                      <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                        Continue research and collect preliminary results
                      </p>
                    </li>
                    <li>
                      <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                        Collect results and write methods and results sections
                      </p>
                    </li>
                    <li>
                      <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                        Perform ablations and write conclusion section
                      </p>
                    </li>
                    <li>
                      <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                        Polish final paper and presentation
                      </p>
                    </li>
                    {/* <li>
                      <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                        Designed a diffusion 
                      </p>
                    </li> */}
                  </ul>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={`${process.env.REACT_APP_CLOUDFLARE_URL}/research-schedule.png`}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;