import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const AISummerProgram = () => {
  const pageTitle = "High School AI Summer Program | Learn from Stanford/MIT Experts";
  const pageDescription = "Transform your future with BLAST AI's elite summer program. Master AI & ML with top university mentors, build real projects, and strengthen your college applications.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "BLAST AI Summer Program",
    "description": "An intensive summer program teaching artificial intelligence to high school students",
    "provider": {
      "@type": "Organization",
      "name": "BLAST AI",
      "sameAs": "https://blastai.org"
    },
    "courseCode": "BLAST-SUMMER-2025",
    "educationalLevel": "High School",
    "hasCourseInstance": {
      "@type": "CourseInstance",
      "courseMode": "online",
      "duration": "P8W",
      "startDate": "2025-06-17",
      "endDate": "2025-08-12",
      "price": 1460,
      "priceCurrency": "USD"
    },
    "teaches": [
      "Artificial Intelligence",
      "Machine Learning",
      "Neural Networks",
      "Computer Vision",
      "Natural Language Processing",
      "Project Development"
    ],
    "educationalCredentialAwarded": "Certificate of Completion"
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blastai.org/ai-summer-program" />
        <link rel="canonical" href="https://blastai.org/ai-summer-program" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div>
        <h1>High School AI Summer Program: Learn from Stanford/MIT Experts</h1>
        <Research />
      </div>
    </>
  );
};

export default AISummerProgram; 