import React, { Component, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSplit from '../components/sections/HeroSplit';
import ResearchCarosel2 from '../components/sections/ResearchCarosel2';
import Mentors from '../components/sections/MentorHighlight';
import FAQ from '../components/sections/FAQ';
import Numbers from '../components/sections/Numbers';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Map from '../components/sections/Map';
import Mission from '../components/sections/MissionStatement'
import ProgramTimeline from '../components/sections/ProgramTimeline';
import EmailPopup from '../components/sections/EmailPopup';
import { Widget } from '@typeform/embed-react'

class Home extends React.Component {
  render() {
    const organizationSchema = {
      "@context": "https://schema.org",
      "@type": "EducationalOrganization",
      "name": "BLAST AI",
      "url": "https://blastai.org",
      "logo": "https://blastai.org/blast-ai-logo.png",
      "description": "BLAST AI offers premier AI education programs for high school students with Stanford/MIT mentors.",
      "address": {
        "@type": "PostalAddress",
        "addressCountry": "US"
      },
      "sameAs": [
        "https://www.linkedin.com/company/blast-ai",
        "https://twitter.com/blast_ai"
      ],
      "hasCourseInstance": [{
        "@type": "CourseInstance",
        "courseMode": "online",
        "startDate": "2025-06-17",
        "endDate": "2025-08-12",
        "price": 1460,
        "priceCurrency": "USD",
        "offers": {
          "@type": "Offer",
          "price": "1460",
          "priceCurrency": "USD",
          "availability": "https://schema.org/InStock",
          "validFrom": "2024-02-01",
          "url": "https://blastai.org/apply"
        }
      }],
      "hasCourse": {
        "@type": "Course",
        "name": "AI Research Program",
        "courseCode": "BLAST-RESEARCH-2025",
        "description": "Intensive AI research program covering machine learning, deep learning, and neural networks",
        "educationalLevel": "High School",
        "timeRequired": "P8W",
        "provider": {
          "@type": "Organization",
          "name": "BLAST AI",
          "url": "https://blastai.org"
        },
        "offers": {
          "@type": "Offer",
          "price": "1460",
          "category": "AI Research Program for High School Students and AI Summer Camp",
          "priceCurrency": "USD",
          "availability": "https://schema.org/InStock",
          "validFrom": "2024-02-01",
          "url": "https://blastai.org/apply"
        },
        "teaches": [
          "Machine Learning",
          "Deep Learning",
          "Neural Networks",
          "Research Methodology",
          "AI Development"
        ]
      },
      "alumni": {
        "@type": "OrganizationRole",
        "description": "Our alumni continue their education at prestigious institutions including Stanford, MIT, and other top universities."
      }
    };

    return (
      <>
        <Helmet>
          <title>AI Research for High School Students | BLAST AI | AI Summer Camp</title>
          <meta name="description" content="Join BLAST AI's premier 8-week AI research program where high school students work with Stanford/MIT mentors to conduct and publish groundbreaking AI research. Learn advanced machine learning, deep learning, and neural networks while building a competitive college application portfolio. Summer 2025 applications now open." />
          <link rel="canonical" href="https://www.blastai.org/" />
          <script type="application/ld+json">
            {JSON.stringify(organizationSchema)}
          </script>
        </Helmet>
        <main>
          <header>
            <HeroSplit />
          </header>
          <article>
            <Numbers className="numbers-section" />
            <Mission id="About" />
            <ResearchCarosel2 />
            <ProgramTimeline />
            <FeaturesSplit id="About" />
            <section className="pt-8" style={{display: 'flex', justifyContent:'center', width: '100%'}}>
              <Widget id="eta8tr1k" style={{ width: '100%', height: '515px' }} className="my-form" />
            </section>
            <Mentors />
            <Map />
            <FAQ />
            <EmailPopup />
          </article>
        </main>
      </>
    );
  }
}

export default Home;