import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSplit from '../../components/sections/HeroSplit';
import FeaturesSplit from '../../components/sections/FeaturesSplit';
import Testimonial from '../../components/sections/Testimonial';
import FAQ from '../../components/sections/FAQ';
import Image from '../../components/elements/Image';

const pageTitle = "ML Engineer Internships | AI Career Growth";
const pageDescription = "Find the best machine learning engineer internships and accelerate your AI career. Get matched with top companies hiring ML interns.";

const structuredData = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": pageTitle,
  "description": pageDescription,
  "url": "https://aicareer.com/ml-engineer-internships",
  "mainEntity": {
    "@type": "Article",
    "headline": "Machine Learning Engineer Internship Opportunities",
    "description": pageDescription,
    "image": "https://aicareer.com/images/ml-internships-hero.jpg",
    "author": {
      "@type": "Organization",
      "name": "AI Career Growth"
    }
  }
};

class MLEngineerInternships extends React.Component {
  render() {
    return (
      <main className="ml-engineer-internships-page">
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <link rel="canonical" href="https://aicareer.com/ml-engineer-internships" />
          
          {/* OpenGraph Tags */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://aicareer.com/ml-engineer-internships" />
          <meta property="og:image" content="https://aicareer.com/images/ml-internships-hero.jpg" />
          
          {/* Twitter Card Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
          <meta name="twitter:image" content="https://aicareer.com/images/ml-internships-hero.jpg" />
          
          <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        </Helmet>
        <header>
          <div className="container-xl">
            <div className="hero-content">
              <h1 className="mt-20 mb-16 reveal-from-bottom h2-style" data-reveal-delay="200">
                Machine Learning Engineer Internship Opportunities
              </h1>
              <div className="container-lg">
                <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  Explore top machine learning engineering internships at leading tech companies. Learn about required skills, application processes, and how to prepare.
                </p>
              </div>
            </div>
          </div>
        </header>
        
        <article>
          <div className="container-xl">
            <div className="features-split-inner section-inner has-top-divider mt-10">
              <div className="split-wrap">
                
                <div className="split-item">
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Top Companies Hiring ML Engineer Interns</h3>
                    <p className="m-0">
                      Leading companies offering ML engineering internships:
                      <ul>
                        <li>Google AI/ML Teams</li>
                        <li>Amazon AWS ML</li>
                        <li>Microsoft Azure AI</li>
                        <li>Meta AI</li>
                        <li>Apple Machine Learning</li>
                        <li>NVIDIA AI Research</li>
                      </ul>
                    </p>
                  </div>
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/bootcamp.jpg`}
                      alt="Top ML Companies"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                </div>

                <div className="split-item">
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/nishkal-transformer-lecture.jpg`}
                      alt="Technical Skills"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Required Technical Skills</h3>
                    <p className="m-0">
                      Essential skills for ML Engineer interns:
                      <ul>
                        <li>Strong software engineering fundamentals</li>
                        <li>Experience with ML frameworks and tools</li>
                        <li>Knowledge of ML deployment and MLOps</li>
                        <li>Understanding of distributed systems</li>
                        <li>Proficiency in Python, Java, or C++</li>
                      </ul>
                    </p>
                  </div>
                </div>

                <div className="split-item">
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">How to Prepare</h3>
                    <p className="m-0">
                      Prepare for ML Engineer internships by:
                      <ul>
                        <li>Building end-to-end ML projects</li>
                        <li>Contributing to open-source ML tools</li>
                        <li>Learning cloud platforms (AWS, GCP, Azure)</li>
                        <li>Practicing system design interviews</li>
                        <li>Understanding ML deployment challenges</li>
                      </ul>
                    </p>
                  </div>
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/calista-research-presentation.jpg`}
                      alt="Preparation Guide"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </article>
 
        <FAQ />
      </main>
    );
  }
}

export default MLEngineerInternships; 