import React from 'react';
import { withRouter, Switch, Route, useSearchParams, BrowserRouter} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import './tailwind.css';
import { useAnalytics } from './hooks/useAnalytics';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutAlternative from './layouts/LayoutAlternative';
import LayoutAlt2 from './layouts/LayoutAlt2';
import LayoutSignin from './layouts/LayoutSignin';
import LayoutPlain from './layouts/LayoutPlain';
import LayoutPlainHeader from './layouts/LayoutPlainHeader';

// Views 
import Home from './views/Home';
import Curriculum from './views/Bootcamp';
import Symposium from './views/Research';
import AcceptanceResearch from './views/Acceptance-Research';
import RejectResearch from './views/Reject-Research';
import Login from './views/temp';
import Team from './views/Team';
import PR from './views/PR';
import ResearchApplication from './views/ResearchApplication';
import ResearchAcceptWithPayment from './views/ResearchAcceptWithPayment';
import AISummerProgram from './views/landing/AISummerProgram';
import AIResearchProgram from './views/landing/AIResearchProgram';
import AIBootcamp from './views/landing/AIBootcamp';
import MLInternships from './views/landing/MLInternships';
import AppleMLInternships from './views/landing/AppleMLInternships';
import MLEngineerInternships from './views/landing/MLEngineerInternships';
import InternshipBlog from './views/blog/InternshipBlog';
import HighSchoolResearch from './views/landing/HighSchoolResearch';
import ResearchPaperGuide from './views/landing/ResearchPaperGuide';
import AISummerSchool from './views/landing/AISummerSchool';
import DeepLearningCourse from './views/landing/DeepLearningCourse';
import MLResearchOpportunities from './views/landing/MLResearchOpportunities';
import AIHackathon from './views/landing/AIHackathon';
import DataScienceInstitute from './views/landing/DataScienceInstitute';
import StanfordMentorship from './views/landing/StanfordMentorship';
import AISummerCamp from './views/landing/AISummerCamp';
import HighSchoolResearchOpportunities from './views/landing/HighSchoolResearchOpportunities';
import HighSchoolResearchOpportunities2 from './views/landing/HighSchoolResearchOpportunities2';
import HighSchoolAICamp from './views/landing/HighSchoolAICamp';
import BlockBlastAI from './views/landing/BlockBlastAI';
import AIResearchProgramsHS from './views/landing/AIResearchProgramsHS';
import MLHighSchoolCamp from './views/landing/MLHighSchoolCamp';
import AISummerProgramHS from './views/landing/AISummerProgramHS';
import TeenAIAcademy from './views/landing/TeenAIAcademy';
import DeepLearningHS from './views/landing/DeepLearningHS';
import YouthAIInstitute from './views/landing/YouthAIInstitute';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: null,
      fullName: ''
    };

  }
  setFullName = (fullName) => {
    this.setState({ fullName });
  }

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
    // this.scrollRevealRef.current.init();
    // this.init();

  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
      // this.scrollRevealRef.current.init();
      // this.init();
      // console.log('Route changed:', this.props.history);
    }
  }

  render() {
    return (
      <BrowserRouter>
        <HelmetProvider>
          <AnalyticsWrapper>
            <ScrollReveal
              ref="scrollReveal"
              children={() => (
                <Switch>
                  <AppRoute exact path="/" component={Home} layout={LayoutDefault} canonicalUrl="https://blastai.org/" />
                  <AppRoute exact path="/bootcamp" component={Curriculum} layout={LayoutAlternative} canonicalUrl="https://blastai.org/bootcamp" />
                  <AppRoute exact path="/research" component={Symposium} layout={LayoutAlternative} canonicalUrl="https://blastai.org/research" />
                  <AppRoute exact path="/testimonials" component={PR} layout={LayoutDefault} canonicalUrl="https://blastai.org/testimonials" />
                  <AppRoute exact path="/team" component={Team} layout={LayoutDefault} canonicalUrl="https://blastai.org/team" />

                  <Route exact path="/temp">
                    <Login onLogin={this.onLogin} />
                  </Route>

                  <AppRoute exact path="/apply-research" component={ResearchApplication} layout={LayoutPlain} canonicalUrl="https://blastai.org/apply" />
                  <AppRoute exact path="/apply" component={ResearchApplication} layout={LayoutPlain} canonicalUrl="https://blastai.org/apply" />

                  <AppRoute path="/letter/:userId" component={AcceptanceResearch} layout={LayoutAlt2}/>
                  <AppRoute path="/decision/:userId" component={RejectResearch} layout={LayoutAlt2}/>
                  <AppRoute path="/commit/:userId" component={ResearchAcceptWithPayment} layout={LayoutAlt2}/>

                  <Route path='/apply-fa-23' component={() => {
                    window.location.href = '/apply';
                    return null;
                  }}/>
                  <Route path='/applyfall23' component={() => {
                    window.location.href = '/apply';
                    return null;
                  }}/>
                  
                  <Route path='/bootcamp-application' component={() => {
                    window.location.href = 'https://forms.gle/aX4j2ZZfU1Q4b9pN8';
                    return null;
                  }}/>
                  <Route path='/research-application' component={() => {
                    window.location.href = 'apply';
                    return null;
                  }}/>
                  <Route path='/hiring' component={() => {
                    window.location.href = 'https://airtable.com/appjqjUChqruvhQaS/pag2uWtHvW71PTUHx/form';
                    return null;
                  }}/>
                  <Route path='/blastoff-2025-accept' component={() => {
                    window.location.href = 'https://wnewxg7rxr5.typeform.com/to/It50iMMJ';
                    return null;
                  }}/>
                  <Route path='/financial-aid-application' component={() => {
                    window.location.href = 'https://forms.gle/ppZKduUegpyjbHgGA';
                    return null;
                  }}/>
                  <Route path='/apply-to-blast' component={() => {
                    window.location.href = 'apply';
                    return null;
                  }}/>

                  {/* SEO-optimized landing pages */}
                  <AppRoute exact path="/ai-summer-program" component={AISummerProgram} layout={LayoutAlternative} canonicalUrl="https://blastai.org/ai-summer-program" />
                  <AppRoute exact path="/high-school-ai-program" component={AIResearchProgram} layout={LayoutAlternative} canonicalUrl="https://blastai.org/ai-research-program" />
                  <AppRoute exact path="/ai-research-program" component={AIResearchProgram} layout={LayoutAlternative} canonicalUrl="https://blastai.org/ai-research-program" />
                  <AppRoute exact path="/ai-bootcamp" component={AIBootcamp} layout={LayoutAlternative} canonicalUrl="https://blastai.org/ai-bootcamp" />
                  <AppRoute exact path="/summer-ai-course" component={AISummerProgram} layout={LayoutAlternative} canonicalUrl="https://blastai.org/ai-summer-program" />
                  <AppRoute exact path="/ai-program-high-school" component={AIResearchProgram} layout={LayoutAlternative} canonicalUrl="https://blastai.org/ai-research-program" />
                  <AppRoute exact path="/machine-learning-program" component={AIResearchProgram} layout={LayoutAlternative} canonicalUrl="https://blastai.org/ai-research-program" />
                  <AppRoute exact path="/artificial-intelligence-course" component={AIResearchProgram} layout={LayoutAlternative} canonicalUrl="https://blastai.org/ai-research-program" />
                  <AppRoute exact path="/ai-summer-camp" component={AISummerCamp} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-camp" />
                  <AppRoute exact path="/summer-ai-camp" component={AISummerCamp} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-camp" />
                  <AppRoute exact path="/ai-camp" component={AISummerCamp} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-camp" />
                  <AppRoute exact path="/ai-camp-high-school" component={AISummerCamp} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-camp" />
                  <AppRoute exact path="/ai-research-opportunities" component={AIResearchProgram} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-research-program" />

                  {/* Research pages */}
                  <AppRoute exact path="/high-school-research" component={HighSchoolResearch} layout={LayoutDefault} canonicalUrl="https://blastai.org/high-school-research-opportunities" />
                  <AppRoute exact path="/high-school-research-opportunities" component={HighSchoolResearchOpportunities2} layout={LayoutDefault} canonicalUrl="https://blastai.org/high-school-research-opportunities" />
                  <AppRoute exact path="/research-opportunities-high-school" component={HighSchoolResearchOpportunities2} layout={LayoutDefault} canonicalUrl="https://blastai.org/high-school-research-opportunities" />
                  <AppRoute exact path="/student-research-opportunities" component={HighSchoolResearchOpportunities2} layout={LayoutDefault} canonicalUrl="https://blastai.org/high-school-research-opportunities" />
                  <AppRoute exact path="/research-programs-high-school" component={HighSchoolResearchOpportunities2} layout={LayoutDefault} canonicalUrl="https://blastai.org/high-school-research-opportunities" />
                  <AppRoute exact path="/high-school-research-programs" component={HighSchoolResearchOpportunities2} layout={LayoutDefault} canonicalUrl="https://blastai.org/high-school-research-opportunities" />
                  <AppRoute exact path="/research-opportunities-for-high-school-students" component={HighSchoolResearchOpportunities2} layout={LayoutDefault} canonicalUrl="https://blastai.org/high-school-research-opportunities" />
                  <AppRoute exact path="/high-school-student-research" component={HighSchoolResearchOpportunities2} layout={LayoutDefault} canonicalUrl="https://blastai.org/high-school-research-opportunities" />
                  <AppRoute exact path="/research-paper-guide" component={ResearchPaperGuide} layout={LayoutDefault} canonicalUrl="https://blastai.org/research-paper-guide" />

                  {/* Internship and program pages */}
                  <AppRoute exact path="/machine-learning-internships" component={MLInternships} layout={LayoutDefault} canonicalUrl="https://blastai.org/machine-learning-internships" />
                  <AppRoute exact path="/apple-machine-learning-internship" component={AppleMLInternships} layout={LayoutDefault} canonicalUrl="https://blastai.org/machine-learning-internships" />
                  <AppRoute exact path="/machine-learning-engineer-internships" component={MLEngineerInternships} layout={LayoutDefault} canonicalUrl="https://blastai.org/machine-learning-internships" />
                  <AppRoute exact path="/blog/internships" component={InternshipBlog} layout={LayoutDefault} canonicalUrl="https://blastai.org/blog/internships" />
                  <AppRoute exact path="/ai-summer-school" component={AISummerSchool} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-program" />
                  <AppRoute exact path="/deep-learning-summer-course" component={DeepLearningCourse} layout={LayoutDefault} canonicalUrl="https://blastai.org/deep-learning-summer-course" />
                  <AppRoute exact path="/machine-learning-research-opportunities" component={MLResearchOpportunities} layout={LayoutDefault} canonicalUrl="https://blastai.org/machine-learning-research-opportunities" />
                  <AppRoute exact path="/ai-hackathon-high-school" component={AIHackathon} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-hackathon-high-school" />
                  <AppRoute exact path="/data-science-summer-institute" component={DataScienceInstitute} layout={LayoutDefault} canonicalUrl="https://blastai.org/data-science-summer-institute" />
                  <AppRoute exact path="/stanford-mentored-ai-program" component={StanfordMentorship} layout={LayoutDefault} canonicalUrl="https://blastai.org/stanford-mentored-ai-program" />

                  {/* High School AI Camp Routes */}
                  <AppRoute exact path="/high-school-ai-camp" component={HighSchoolAICamp} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-camp" />
                  <AppRoute exact path="/ai-camp-high-school" component={HighSchoolAICamp} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-camp" />
                  <AppRoute exact path="/high-school-artificial-intelligence-camp" component={HighSchoolAICamp} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-camp" />
                  <AppRoute exact path="/teen-ai-camp" component={HighSchoolAICamp} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-camp" />
                  <AppRoute exact path="/ai-camp-for-teens" component={HighSchoolAICamp} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-camp" />
                  <AppRoute exact path="/high-school-ai-program" component={HighSchoolAICamp} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-research-program" />
                  <AppRoute exact path="/artificial-intelligence-camp-high-school" component={HighSchoolAICamp} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-camp" />

                  {/* Block.BLAST AI Routes */}
                  <AppRoute exact path="/block-blast-ai" component={BlockBlastAI} layout={LayoutDefault} canonicalUrl="https://blastai.org/block-blast-ai" />
                  <AppRoute exact path="/visual-ai-programming" component={BlockBlastAI} layout={LayoutDefault} canonicalUrl="https://blastai.org/block-blast-ai" />
                  <AppRoute exact path="/block-coding-ai" component={BlockBlastAI} layout={LayoutDefault} canonicalUrl="https://blastai.org/block-blast-ai" />
                  <AppRoute exact path="/block-based-ai" component={BlockBlastAI} layout={LayoutDefault} canonicalUrl="https://blastai.org/block-blast-ai" />
                  <AppRoute exact path="/visual-ai-learning" component={BlockBlastAI} layout={LayoutDefault} canonicalUrl="https://blastai.org/block-blast-ai" />
                  <AppRoute exact path="/block-ai-platform" component={BlockBlastAI} layout={LayoutDefault} canonicalUrl="https://blastai.org/block-blast-ai" />

                  {/* AI Research Programs for High School Routes */}
                  <AppRoute exact path="/ai-research-programs-high-school" component={AIResearchProgramsHS} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-research-program" />
                  <AppRoute exact path="/high-school-ai-research" component={AIResearchProgramsHS} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-research-program" />
                  <AppRoute exact path="/ai-research-opportunities-students" component={AIResearchProgramsHS} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-research-program" />
                  <AppRoute exact path="/student-ai-research-programs" component={AIResearchProgramsHS} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-research-program" />
                  <AppRoute exact path="/high-school-ai-research-opportunities" component={AIResearchProgramsHS} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-research-program" />
                  <AppRoute exact path="/ai-research-for-high-school-students" component={AIResearchProgramsHS} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-research-program" />

                  {/* Machine Learning High School Camp Routes */}
                  <AppRoute exact path="/machine-learning-high-school-camp" component={MLHighSchoolCamp} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-camp" />
                  <AppRoute exact path="/ml-camp-high-school" component={MLHighSchoolCamp} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-camp" />
                  <AppRoute exact path="/machine-learning-camp-teens" component={MLHighSchoolCamp} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-camp" />
                  <AppRoute exact path="/high-school-ml-program" component={MLHighSchoolCamp} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-camp" />
                  <AppRoute exact path="/teen-machine-learning-camp" component={MLHighSchoolCamp} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-camp" />
                  <AppRoute exact path="/ml-summer-program-high-school" component={MLHighSchoolCamp} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-camp" />

                  {/* AI Summer Program for High School Routes */}
                  <AppRoute exact path="/ai-summer-program-high-school" component={AISummerProgramHS} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-program" />
                  <AppRoute exact path="/high-school-ai-summer-program" component={AISummerProgramHS} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-program" />
                  <AppRoute exact path="/summer-ai-program-teens" component={AISummerProgramHS} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-program" />
                  <AppRoute exact path="/ai-program-high-school-summer" component={AISummerProgramHS} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-program" />
                  <AppRoute exact path="/teen-ai-summer-program" component={AISummerProgramHS} layout={LayoutDefault} canonicalUrl="https://blastai.org/ai-summer-program" />

                  {/* Teen AI Academy Routes */}
                  <AppRoute exact path="/teen-ai-academy" component={TeenAIAcademy} layout={LayoutDefault} canonicalUrl="https://blastai.org/teen-ai-academy" />
                  <AppRoute exact path="/ai-academy-teens" component={TeenAIAcademy} layout={LayoutDefault} canonicalUrl="https://blastai.org/teen-ai-academy" />
                  <AppRoute exact path="/teen-artificial-intelligence-academy" component={TeenAIAcademy} layout={LayoutDefault} canonicalUrl="https://blastai.org/teen-ai-academy" />
                  <AppRoute exact path="/youth-ai-academy" component={TeenAIAcademy} layout={LayoutDefault} canonicalUrl="https://blastai.org/teen-ai-academy" />
                  <AppRoute exact path="/ai-academy-young-learners" component={TeenAIAcademy} layout={LayoutDefault} canonicalUrl="https://blastai.org/teen-ai-academy" />

                  {/* Deep Learning High School Routes */}
                  <AppRoute exact path="/deep-learning-high-school" component={DeepLearningHS} layout={LayoutDefault} canonicalUrl="https://blastai.org/deep-learning-high-school" />
                  <AppRoute exact path="/high-school-deep-learning-program" component={DeepLearningHS} layout={LayoutDefault} canonicalUrl="https://blastai.org/deep-learning-high-school" />
                  <AppRoute exact path="/neural-networks-high-school" component={DeepLearningHS} layout={LayoutDefault} canonicalUrl="https://blastai.org/deep-learning-high-school" />
                  <AppRoute exact path="/deep-learning-summer-high-school" component={DeepLearningHS} layout={LayoutDefault} canonicalUrl="https://blastai.org/deep-learning-high-school" />
                  <AppRoute exact path="/teen-deep-learning-program" component={DeepLearningHS} layout={LayoutDefault} canonicalUrl="https://blastai.org/deep-learning-high-school" />

                  {/* Youth AI Institute Routes */}
                  <AppRoute exact path="/youth-ai-institute" component={YouthAIInstitute} layout={LayoutDefault} canonicalUrl="https://blastai.org/youth-ai-institute" />
                  <AppRoute exact path="/youth-artificial-intelligence-institute" component={YouthAIInstitute} layout={LayoutDefault} canonicalUrl="https://blastai.org/youth-ai-institute" />
                  <AppRoute exact path="/ai-institute-young-learners" component={YouthAIInstitute} layout={LayoutDefault} canonicalUrl="https://blastai.org/youth-ai-institute" />
                  <AppRoute exact path="/teen-ai-institute" component={YouthAIInstitute} layout={LayoutDefault} canonicalUrl="https://blastai.org/youth-ai-institute" />
                  <AppRoute exact path="/student-ai-institute" component={YouthAIInstitute} layout={LayoutDefault} canonicalUrl="https://blastai.org/youth-ai-institute" />
                </Switch>
              )} />
          </AnalyticsWrapper>
        </HelmetProvider>
      </BrowserRouter>
    );
  }
}

// Analytics wrapper component
const AnalyticsWrapper = ({ children }) => {
  useAnalytics();
  return children;
};

export default withRouter(props => <App {...props} />);
