import React from 'react';
import PropTypes from 'prop-types';

const VisuallyHidden = ({ as: Component = 'span', children, className = '', ...props }) => {
  return (
    <Component
      className={`${className} visually-hidden`}
      style={{
        position: 'absolute',
        width: '1px',
        height: '1px',
        padding: 0,
        margin: '-1px',
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        whiteSpace: 'nowrap',
        border: 0,
        zIndex: 0
      }}
      {...props}
    >
      {children}
    </Component>
  );
};

VisuallyHidden.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default VisuallyHidden; 