import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSplit from '../../components/sections/HeroSplit';
import FeaturesSplit from '../../components/sections/FeaturesSplit';
import Testimonial from '../../components/sections/Testimonial';
import FAQ from '../../components/sections/FAQ';
import Image from '../../components/elements/Image';

const pageTitle = "AI Hackathon for High School Students | 48-Hour Innovation";
const pageDescription = "AI hackathon with machine learning, computer vision, NLP challenges. Build innovative projects, win prizes, get mentored by Stanford experts. Perfect for high school innovators.";

const structuredData = {
  "@context": "http://schema.org",
  "@type": "Event",
  "name": "AI Hackathon for High School Students",
  "description": pageDescription,
  "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
  "eventStatus": "https://schema.org/EventScheduled",
  "startDate": "2025-06-17",
  "endDate": "2025-06-19",
  "location": {
    "@type": "VirtualLocation",
    "url": "https://blastai.org/ai-hackathon-high-school"
  },
  "offers": {
    "@type": "Offer",
    "availability": "https://schema.org/InStock",
    "price": "0",
    "priceCurrency": "USD"
  },
  "organizer": {
    "@type": "Organization",
    "name": "BLAST AI",
    "url": "https://blastai.org"
  }
};

class AIHackathon extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <link rel="canonical" href="https://blastai.org/ai-hackathon-high-school" />
          
          {/* OpenGraph Tags */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://blastai.org/ai-hackathon-high-school" />
          <meta property="og:image" content="https://blastai.org/ai-hackathon.jpg" />
          
          {/* Twitter Card Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />

          {/* Keywords and SEO */}
          <meta name="keywords" content="ai hackathon, high school hackathon, machine learning competition, ai competition, student hackathon, coding competition, stanford mentored hackathon, ai project competition, teen coding event, high school ai challenge" />
          <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large" />

          {/* Add CSS for visually hidden elements */}
          <style>
            {`
              .visually-hidden {
                position: absolute;
                width: 1px;
                height: 1px;
                padding: 0;
                margin: -1px;
                overflow: hidden;
                clip: rect(0, 0, 0, 0);
                white-space: nowrap;
                border: 0;
              }
            `}
          </style>

          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
        <main className="site-content">
          <h1 className="visually-hidden">{pageTitle}</h1>
          
          <header>
            <div className="container-xl">
              <div className="hero-content">
                <h2 className="mt-20 mb-16 reveal-from-bottom text-h2" data-reveal-delay="200">
                  AI Hackathon for High School Students
                </h2>
                <div className="container-lg">
                  <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                    Join our intensive AI hackathon where high school students build innovative AI solutions. Work with Stanford and MIT mentors to develop cutting-edge projects in just 48 hours.
                  </p>
                </div>
              </div>
            </div>
          </header>
          
          <article>
            <div className="container">
              <div className="features-split-inner section-inner has-top-divider mt-10">
                <div className="split-wrap">
                  
                  <div className="split-item">
                    <div className="split-item-content center-content-mobile">
                      <h3 className="mt-0 mb-16">Hackathon Challenges</h3>
                      <p className="m-0">
                        Choose from exciting AI challenges:
                        <ul>
                          <li>Medical AI Applications</li>
                          <li>Environmental Conservation</li>
                          <li>Education Technology</li>
                          <li>Social Impact Projects</li>
                          <li>Computer Vision Solutions</li>
                          <li>NLP Applications</li>
                        </ul>
                      </p>
                    </div>
                    <div className="split-item-image center-content-mobile">
                      <Image
                        src={`${process.env.REACT_APP_CLOUDFLARE_URL}/healthcare.jpg`}
                        alt="AI Challenges"
                        width={528}
                        height={396}
                        style={{objectFit: 'cover'}} />
                    </div>
                  </div>

                  <div className="split-item">
                    <div className="split-item-image center-content-mobile">
                      <Image
                        src={`${process.env.REACT_APP_CLOUDFLARE_URL}/jeopardy.jpg`}
                        alt="Learning Experience"
                        width={528}
                        height={396}
                        style={{objectFit: 'cover'}} />
                    </div>
                    <div className="split-item-content center-content-mobile">
                      <h3 className="mt-0 mb-16">Learning Experience</h3>
                      <p className="m-0">
                        Gain hands-on experience with:
                        <ul>
                          <li>Rapid Prototyping</li>
                          <li>Team Collaboration</li>
                          <li>Project Management</li>
                          <li>Technical Presentation</li>
                          <li>Problem-Solving Skills</li>
                          <li>Real-World Applications</li>
                        </ul>
                      </p>
                    </div>
                  </div>

                  <div className="split-item">
                    <div className="split-item-content center-content-mobile">
                      <h3 className="mt-0 mb-16">Expert Support</h3>
                      <p className="m-0">
                        Access to industry experts:
                        <ul>
                          <li>Stanford AI Researchers</li>
                          <li>MIT Graduate Students</li>
                          <li>Industry Professionals</li>
                          <li>Technical Mentors</li>
                          <li>Project Advisors</li>
                          <li>Career Counselors</li>
                        </ul>
                      </p>
                    </div>
                    <div className="split-item-image center-content-mobile">
                      <Image
                        src={`${process.env.REACT_APP_CLOUDFLARE_URL}/ppl1.jpg`}
                        alt="Expert Support"
                        width={528}
                        height={396}
                        style={{objectFit: 'cover'}} />
                    </div>
                  </div>

                  <div className="split-item">
                    <div className="split-item-image center-content-mobile">
                      <Image
                        src={`${process.env.REACT_APP_CLOUDFLARE_URL}/molecular.jpg`}
                        alt="Technologies"
                        width={528}
                        height={396}
                        style={{objectFit: 'cover'}} />
                    </div>
                    <div className="split-item-content center-content-mobile">
                      <h3 className="mt-0 mb-16">Technologies and Tools</h3>
                      <p className="m-0">
                        Work with cutting-edge tech:
                        <ul>
                          <li>Deep Learning Frameworks</li>
                          <li>Cloud Computing Platforms</li>
                          <li>Version Control Systems</li>
                          <li>Collaboration Tools</li>
                          <li>Development Environments</li>
                          <li>AI Model Deployment</li>
                        </ul>
                      </p>
                    </div>
                  </div>

                  <div className="split-item">
                    <div className="split-item-content center-content-mobile">
                      <h3 className="mt-0 mb-16">Prizes and Recognition</h3>
                      <p className="m-0">
                        Compete for amazing rewards:
                        <ul>
                          <li>Cash Prizes</li>
                          <li>Internship Opportunities</li>
                          <li>Mentorship Programs</li>
                          <li>Research Opportunities</li>
                          <li>Industry Recognition</li>
                          <li>Project Publication</li>
                        </ul>
                      </p>
                    </div>
                    <div className="split-item-image center-content-mobile">
                      <Image
                        src={`${process.env.REACT_APP_CLOUDFLARE_URL}/congress-app-challenge.png`}
                        alt="Prizes and Recognition"
                        width={528}
                        height={396}
                        style={{objectFit: 'cover'}} />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </article>
   
          <FAQ />
        </main>
      </>
    );
  }
}

export default AIHackathon; 