import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const BlockBlastAI = () => {
  const pageTitle = "Block.BLAST AI: Visual AI Learning Platform for Beginners";
  const pageDescription = "Transform your AI learning journey with Block.BLAST AI. Build real machine learning projects using visual blocks - perfect for students with no coding experience.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Block.BLAST AI",
    "applicationCategory": "Educational Software",
    "description": pageDescription,
    "operatingSystem": "Web-based",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "author": {
      "@type": "Organization",
      "name": "BLAST AI",
      "url": "https://blastai.org"
    },
    "audience": {
      "@type": "EducationalAudience",
      "educationalRole": "student"
    },
    "educationalUse": "Interactive learning",
    "learningResourceType": "Visual Programming Tool",
    "interactivityType": "active"
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* OpenGraph tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://block.blastai.org" />
        <meta property="og:image" content="https://blastai.org/block-blast-ai-logo.png" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        
        {/* Keywords targeting block programming and AI learning */}
        <meta name="keywords" content="block.blast ai, visual ai programming, block coding ai, drag and drop ai, learn ai programming, ai blocks, visual machine learning, block based programming ai, ai for beginners, block coding platform, visual ai learning, block.blast artificial intelligence, ai education platform" />
        
        {/* Additional SEO tags */}
        <meta name="author" content="BLAST AI" />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large" />
        <link rel="canonical" href="https://block.blastai.org" />

        {/* FAQ Schema */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "What is Block.BLAST AI?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Block.BLAST AI is a visual programming platform that allows students to create AI and machine learning projects using drag-and-drop blocks, making AI programming accessible to beginners."
                }
              },
              {
                "@type": "Question",
                "name": "Do I need coding experience to use Block.BLAST AI?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "No coding experience is required. Block.BLAST AI uses a visual block-based interface that makes it easy for beginners to create AI projects without writing complex code."
                }
              }
            ]
          })}
        </script>

        {/* Software Application Schema */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      
      <h1>{pageTitle}</h1>
      
      <Research />
    </>
  );
};

export default BlockBlastAI; 