import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const AIResearchProgramsHS = () => {
  const pageTitle = "High School AI Research Program | Stanford & MIT Mentors";
  const pageDescription = "Join our elite AI research program for high school students. Get mentored by Stanford/MIT experts, conduct original research, and publish in peer-reviewed journals.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "AI Research Programs for High School Students",
    "description": pageDescription,
    "provider": {
      "@type": "Organization",
      "name": "BLAST AI",
      "sameAs": "https://blastai.org"
    },
    "hasCourseInstance": {
      "@type": "CourseInstance",
      "courseMode": "online",
      "startDate": "2025-06-15",
      "endDate": "2025-08-15",
      "academicTerm": "Summer 2025"
    },
    "educationalLevel": "High School",
    "educationalCredentialAwarded": "Research Publication Opportunity",
    "timeRequired": "P8W",
    "teaches": [
      "AI Research Methodology",
      "Machine Learning Research",
      "Deep Learning",
      "Computer Vision",
      "Natural Language Processing",
      "Research Paper Writing",
      "Academic Publication Process"
    ]
  };

  const aggregateRating = {
    "@context": "https://schema.org",
    "@type": "AggregateRating",
    "itemReviewed": {
      "@type": "Course",
      "name": "AI Research Programs for High School Students"
    },
    "ratingValue": "4.9",
    "bestRating": "5",
    "worstRating": "1",
    "ratingCount": "127",
    "reviewCount": "89"
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(aggregateRating)}
        </script>
      </Helmet>
      <h1>{pageTitle}</h1>
      <Research />
    </>
  );
};

export default AIResearchProgramsHS; 