import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import { useAnalytics } from '../../hooks/useAnalytics';
import VisuallyHidden from '../elements/VisuallyHidden';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const HeroSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,      
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const { trackEvent } = useAnalytics();

  const handleApplyClick = () => {
    trackEvent('apply_click', {
      button_location: 'hero_section',
      page_path: window.location.pathname
    });
  };

  const outerClasses = classNames(
    'hero section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const splitClasses = classNames(
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  return (
    <div className="hero-background relative bg-gradient-to-b from-blue-200 to-white">
      <section
        {...props}
        className={outerClasses}
      >
        <div className="hero-container flex items-center min-h-screen">
          <div className={innerClasses}>
            <div className={splitClasses}>
              <div className="hero-content center-content-mobile reveal-from-top w-full">
                <h1 className="mt-15 mb-16">
                  <VisuallyHidden>AI Research Program for High School Students - </VisuallyHidden>
                  BLAST AI
                </h1>
                <p className="mt-0 mb-16 text-gray-600">
                  An intensive online AI research program for talented high school students. 2025 applications are currently being accepted.
                </p>
                <ButtonGroup>
                  <Button tag="a" className="hero-button" color="primary" wideMobile href="/apply" onClick={handleApplyClick}>
                    Apply Now
                  </Button>
                </ButtonGroup>
                <p className="mt-32 mb-16 text-gray-600">
                  Explore the exciting and novel projects completed by BLAST students in past cohorts!
                </p>
                <ButtonGroup>
                  <Button tag="a" className="hero-button" color="dark" href="/research" wideMobile>
                    Read Student Publications
                  </Button>                    
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="absolute bottom-2.5 right-2.5 left-2.5 text-center md:text-right rounded-md p-2 max-w-sm mx-auto md:mx-0 md:ml-auto text-xs">
        <h6 className="text-sm mb-1">
          Developed by researchers from
        </h6>
        <div>
          <Image 
            src={`${process.env.REACT_APP_CLOUDFLARE_URL}/colleges.png`}
            alt="College logos"
            className="max-h-80"
          />
        </div>
      </div>

      <div className="rocket-container hidden lg:block lg:w-4/12">
        {/* First row of two images */}
        <div className="grid grid-cols-2 gap-6 mb-6">
          <div className="relative z-10 transform hover:-translate-y-1 hover:scale-105 transition duration-300">
            <img
              src={`${process.env.REACT_APP_CLOUDFLARE_URL}/ictc2024.png`}
              alt="ICTC 2024"
              className="w-full h-full object-cover rounded-2xl shadow-lg"
            />
          </div>
          <div className="relative z-20 transform hover:-translate-y-1 hover:scale-105 transition duration-300">
            <img
              src={`${process.env.REACT_APP_CLOUDFLARE_URL}/congress-app-challenge.png`}
              alt="Congress App Challenge"
              className="w-full h-full object-cover rounded-2xl shadow-lg"
            />
          </div>
        </div>

        {/* Second row of three images */}
        <div className="grid grid-cols-3 gap-6">
          <div className="relative z-10 transform hover:-translate-y-1 hover:scale-105 transition duration-300">
            <img
              src={`${process.env.REACT_APP_CLOUDFLARE_URL}/scale_paper.jpg`}
              alt="Scale Paper"
              className="w-full h-full object-cover rounded-2xl shadow-lg"
            />
          </div>
          <div className="relative z-20 transform hover:-translate-y-1 hover:scale-105 transition duration-300">
            <img
              src={`${process.env.REACT_APP_CLOUDFLARE_URL}/mike-presenting.png`}
              alt="Student Presentation"
              className="w-full h-full object-cover rounded-2xl shadow-lg"
            />
          </div>
          <div className="relative z-10 transform hover:-translate-y-1 hover:scale-105 transition duration-300">
            <img
              src={`${process.env.REACT_APP_CLOUDFLARE_URL}/rl_paper.jpg`}
              alt="RL Paper"
              className="w-full h-full object-cover rounded-2xl shadow-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

HeroSplit.propTypes = propTypes;
HeroSplit.defaultProps = defaultProps;

export default HeroSplit;
