import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Banner from '../components/layout/Banner';

const LayoutAlternative = ({ children, canonicalUrl }) => (
  <>
    <Helmet>
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
    </Helmet>
    <Banner />
    <div className="flex flex-col min-h-screen padded-content ">
      <Header navPosition="right" className="invert-color" />
      <main className="flex-grow">
        {children}
      </main>
      <Footer />
    </div>
  </>
);

export default LayoutAlternative;  