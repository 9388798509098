import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const AISummerCamp = () => {
  const pageTitle = "AI Summer Camp for High School Students | Learn AI & Robotics";
  const pageDescription = "Transform your future with our elite AI summer camp. Learn AI, robotics, and machine learning from Stanford mentors. Perfect for tech-savvy high school students.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "EducationalEvent",
    "name": "BLAST AI Summer Camp & Artificial Intelligence Summer Camp 2025",
    "description": pageDescription,
    "startDate": "2025-06-17",
    "endDate": "2025-08-12",
    "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
    "eventStatus": "https://schema.org/EventScheduled",
    "organizer": {
      "@type": "Organization",
      "name": "BLAST AI Summer Camp",
      "url": "https://blastai.org",
      "sameAs": [
        "https://www.linkedin.com/company/blast-ai",
        "https://twitter.com/blast_ai"
      ]
    },
    "offers": {
      "@type": "Offer",
      "price": "1460",
      "priceCurrency": "USD",
      "availability": "https://schema.org/InStock",
      "validFrom": "2024-02-01"
    },
    "audience": {
      "@type": "EducationalAudience",
      "educationalRole": "High School Student"
    },
    "teaches": [
      "Artificial Intelligence Summer Camp Curriculum",
      "AI Summer Camp Projects",
      "Machine Learning",
      "Robotics",
      "Neural Networks",
      "Computer Vision",
      "Python Programming"
    ],
    "location": {
      "@type": "VirtualLocation",
      "name": "Online Virtual AI Summer Camp Classroom"
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div>
        <h1>AI Summer Camp for High School Students: Learn AI & Robotics</h1>
        <Research />
      </div>
    </>
  );
};

export default AISummerCamp; 