import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Timeline from '../elements/Timeline';
import TimelineItem from '../elements/TimelineItem';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class ApplicationTimeline extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'roadmap section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'roadmap-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Program Timeline',
      paragraph: 'Throughout the intensive 8-week program, you will learn the fundamentals of machine learning and conduct a novel research project with an experienced mentor.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Timeline>
              <TimelineItem title="Week 1">
                Bootcamp Week 1: Classical Machine Learning
              </TimelineItem>
              <TimelineItem title="Week 2">
                Bootcamp Week 2: Deep Learning
              </TimelineItem>
              <TimelineItem title="Week 3">
                Research Week 1: Literature Review
              </TimelineItem>
              <TimelineItem title="Week 4">
                Research Week 2: Infrastructure Setup and Write Introduction Section
              </TimelineItem>
              <TimelineItem title="Week 5">
                Research Week 3: Initial Experiments and Write Methodology Section
              </TimelineItem>
              <TimelineItem title="Week 6">
                Research Week 4: Continue Experiments and Write Results Section
              </TimelineItem>
              <TimelineItem title="Week 7">
                Research Week 5: Final Experiments and Conclusion Section
              </TimelineItem>
              <TimelineItem title="Week 8">
                Research Week 6: Refine Paper and Prepare for Symposium Presentation
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}

ApplicationTimeline.propTypes = propTypes;
ApplicationTimeline.defaultProps = defaultProps;

export default ApplicationTimeline;