import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const teamMembers = [
  {
    name: 'Peter Wu',
    role: 'Research Mentor',
    description: 'Peter is a PhD candidate at Berkeley researching low-resource NLP and AI for healthcare. He is published in NeurIPS and Nature.',
    imageSrc: `${process.env.REACT_APP_CLOUDFLARE_URL}/peter.jpg`,
    imageAlt: 'Peter Wu',
  },
  {
    name: 'Amisha Kumar',
    role: 'Research Mentor',
    description: 'Amisha is currently attending med school. She\'s conducted genomics research with Nobel Laureates at Stanford and Harvard.',
    imageSrc: `${process.env.REACT_APP_CLOUDFLARE_URL}/amisha.jpg`,
    imageAlt: 'Amisha Kumar',
  },
  {
    name: 'Rishi Jain',
    role: 'Research Mentor',
    description: 'Rishi will attend grad school for ML this fall. He researches RL for task generalization, parameter-efficient tuning of LLMs, and speech science.',
    imageSrc: `${process.env.REACT_APP_CLOUDFLARE_URL}/rishi.jpg`,
    imageAlt: 'Rishi Jain',
  },
];

class Team extends React.Component {

  render() {
    // const [hoveredMember, setHoveredMember] = useState(null);

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'team section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className,
      'py-8'
      // 'bg-gray-200'
    );

    const innerClasses = classNames(
      'team-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left',
      // 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4',
      // pushLeft && 'sm:ml-8 md:ml-12'
    );
    // const tilesClasses = classNames(
    // );


    const sectionHeader = {
      title: 'Mentor Spotlight',
      paragraph: 'BLAST Mentors come from all different backgrounds - research, medicine, and industry. Each mentor brings a unique skillset that allows BLAST students to tackle difficult, ambitious problems.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses} class="px-5">
            <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
            <div className={tilesClasses}>

            <div className={tilesClasses}>
              {teamMembers.map((member, index) => (

                <div key={index} class="p-4 md:w-1/3">
                              
                  <div className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300 px-5 py-5"
                    // onMouseEnter={() => setHoveredMember(index)}
                    // onMouseLeave={() => setHoveredMember(null)}      
                  >
                    <img
                      src={member.imageSrc}
                      alt={member.imageAlt}
                      // width={180}
                      // height={180}
                      class="w-full object-cover object-center rounded-lg"
                    />
                        <div class="">
                      <h5 class="text-lg font-semibold mb-2 p-1">
                        {member.name}
                      </h5>
                      {/* Conditionally render the description based on the hovered state */}
                      {/* {hoveredMember === index && ( */}
                        <p class="text-gray-700 text-base mb-4 transition-opacity duration-300">
                          {member.description}
                        </p>
                      {/* )} */}
                    </div>

                  </div>
                </div>
              

              ))}
            </div>
             
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;