import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSplit from '../../components/sections/HeroSplit';
import FeaturesSplit from '../../components/sections/FeaturesSplit';
import Testimonial from '../../components/sections/Testimonial';
import Numbers from '../../components/sections/Numbers';
import FAQ from '../../components/sections/FAQ';
import Image from '../../components/elements/Image';

// Constants for SEO
const pageTitle = "ML Internship Guide: Launch Your AI Career at Top Tech Companies";
const pageDescription = "Get step-by-step guidance to land prestigious ML internships. Learn required skills, application strategies, and interview prep from industry experts. Start your AI journey today.";
const canonicalUrl = "https://yoursite.com/ml-internships";

const structuredData = {
  "@context": "https://schema.org",
  "@type": "TrainingProgram",
  "name": "Machine Learning Internships 2024",
  "description": pageDescription,
  "provider": {
    "@type": "Organization",
    "name": "Your Organization Name"
  },
  "occupationalCategory": "Machine Learning Engineer",
  "timeToComplete": "P12W",
  "educationalProgramMode": "full-time"
};

class MLInternships extends React.Component {
  render() {
    return (
      <main className="ml-internships-page">
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <link rel="canonical" href={canonicalUrl} />
          
          {/* OpenGraph Tags */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:url" content={canonicalUrl} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://yoursite.com/images/ml-internships-og.jpg" />
          
          {/* Twitter Card Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
          
          {/* Structured Data */}
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>

        <h1>{pageTitle}</h1>
        
        <header>
          <div className="container-xl">
            <div className="hero-content">
              <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                Machine Learning Internships 2024
              </h1>
              <div className="container-lg">
                <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  Discover top machine learning internship opportunities, application guides, and preparation resources. Learn from industry experts and successful ML interns.
                </p>
              </div>
            </div>
          </div>
        </header>
        
        <article>
          <div className="container-xl">
            <div className="features-split-inner section-inner has-top-divider mt-10">
              <div className="split-wrap">
                
                <div className="split-item">
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Top Machine Learning Internship Programs</h3>
                    <p className="m-0">
                      Leading tech companies offer competitive machine learning internships for aspiring ML engineers and researchers. Companies like Google, Apple, Meta, and Microsoft provide hands-on experience with cutting-edge ML technologies and mentorship from industry experts.
                    </p>
                  </div>
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/nishkal-transformer-lecture.jpg`}
                      alt="ML Internship Programs"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                </div>

                <div className="split-item">
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/calista-research-presentation.jpg`}
                      alt="Required Skills for ML Internships"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Required Skills for ML Internships</h3>
                    <p className="m-0">
                      Successful machine learning interns typically have strong foundations in:
                      <ul>
                        <li>Python programming and ML frameworks (TensorFlow, PyTorch)</li>
                        <li>Mathematics and statistics</li>
                        <li>Deep learning and neural networks</li>
                        <li>Data preprocessing and analysis</li>
                        <li>Version control and software development practices</li>
                      </ul>
                    </p>
                  </div>
                </div>

                <div className="split-item">
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Application Timeline and Process</h3>
                    <p className="m-0">
                      Most machine learning internships follow this timeline:
                      <ul>
                        <li>August-September: Applications open for summer internships</li>
                        <li>October-December: Technical interviews and assessments</li>
                        <li>January-February: Final decisions and offers</li>
                        <li>May-August: Internship period</li>
                      </ul>
                    </p>
                  </div>
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/bootcamp.jpg`}
                      alt="Application Timeline"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                </div>

                <div className="split-item">
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/belllecture.jpg`}
                      alt="Preparation Resources"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Preparation Resources</h3>
                    <p className="m-0">
                      Prepare for your machine learning internship with these resources:
                      <ul>
                        <li>Online ML courses and certifications</li>
                        <li>Practice coding interviews on LeetCode and HackerRank</li>
                        <li>Build personal ML projects</li>
                        <li>Join ML communities and forums</li>
                        <li>Read ML research papers and blogs</li>
                      </ul>
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </article>
 
        <FAQ />
      </main>
    );
  }
}

export default MLInternships; 