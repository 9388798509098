import React from 'react';
import { Helmet } from 'react-helmet-async';
import Research from '../Research';

const TeenAIAcademy = () => {
  const pageTitle = "Teen AI Academy 2025 | Stanford-Mentored Summer Program for HS";
  const pageDescription = "Teen AI academy for high school students. Learn artificial intelligence, machine learning, neural networks, deep learning, computer vision, NLP. Stanford mentors, coding projects, AI research. Summer 2025 program.";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "EducationalEvent",
    "name": "Teen AI Summer Academy 2025",
    "description": pageDescription,
    "startDate": "2025-06-15",
    "endDate": "2025-08-15",
    "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
    "eventStatus": "https://schema.org/EventScheduled",
    "organizer": {
      "@type": "Organization",
      "name": "BLAST AI",
      "url": "https://blastai.org"
    },
    "offers": {
      "@type": "Offer",
      "price": "1460",
      "priceCurrency": "USD",
      "availability": "https://schema.org/InStock",
      "validFrom": "2024-02-01"
    },
    "teaches": [
      "AI Development",
      "Machine Learning",
      "Neural Networks",
      "Computer Vision",
      "Natural Language Processing",
      "Python Programming",
      "AI Project Management",
      "Technology Innovation"
    ]
  };

  const aggregateRating = {
    "@context": "https://schema.org",
    "@type": "AggregateRating",
    "itemReviewed": {
      "@type": "Course",
      "name": "Teen AI Summer Academy"
    },
    "ratingValue": "4.9",
    "bestRating": "5",
    "worstRating": "1",
    "ratingCount": "142",
    "reviewCount": "87"
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* OpenGraph tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blastai.org/teen-ai-academy" />
        <meta property="og:image" content="https://blastai.org/blast-ai-academy.png" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        
        {/* Keywords and SEO */}
        <meta name="keywords" content="teen ai academy, artificial intelligence for teens, high school ai program, stanford ai mentorship, machine learning teens, neural networks high school, teen coding academy, ai summer program, teen tech academy, high school ml program" />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large" />
        <link rel="canonical" href="https://blastai.org/teen-ai-academy" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(aggregateRating)}
        </script>

        {/* Add CSS for visually hidden elements */}
        <style>
          {`
            .visually-hidden {
              position: absolute;
              width: 1px;
              height: 1px;
              padding: 0;
              margin: -1px;
              overflow: hidden;
              clip: rect(0, 0, 0, 0);
              white-space: nowrap;
              border: 0;
            }
          `}
        </style>
      </Helmet>
      
      <div className="landing-page">
        <h1 className="visually-hidden">{pageTitle}</h1>
        <Research />
      </div>
    </>
  );
};

export default TeenAIAcademy; 