import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSplit from '../../components/sections/HeroSplit';
import FeaturesSplit from '../../components/sections/FeaturesSplit';
import Testimonial from '../../components/sections/Testimonial';
import FAQ from '../../components/sections/FAQ';
import Image from '../../components/elements/Image';

const pageTitle = "Apple ML Internships - Find Your Next Machine Learning Internship";
const pageDescription = "Discover machine learning internship opportunities at Apple. Learn about requirements, application process, and tips to land your dream ML internship.";

const structuredData = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": pageTitle,
  "description": pageDescription,
  "url": "https://yoursite.com/apple-ml-internships"
};

class AppleMLInternships extends React.Component {
  render() {
    return (
      <main className="apple-ml-internships-page">
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        </Helmet>
        <header>
          <div className="container-xl">
            <div className="hero-content">
              <h1 className="mt-20 mb-16 reveal-from-bottom text-h2" data-reveal-delay="200">
                Apple Machine Learning Internship Guide
              </h1>
              <div className="container-lg">
                <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  Your comprehensive guide to securing a machine learning internship at Apple. Learn about the application process, required skills, and interview preparation tips.
                </p>
              </div>
            </div>
          </div>
        </header>
        
        <article>
          <div className="container-xl">
            <div className="features-split-inner section-inner has-top-divider mt-10">
              <div className="split-wrap">
                
                <div className="split-item">
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">About Apple's ML Internship Program</h3>
                    <p className="m-0">
                      Apple's ML internship program offers opportunities to work on:
                      <ul>
                        <li>Siri and Natural Language Processing</li>
                        <li>Computer Vision and AR/VR</li>
                        <li>On-device Machine Learning</li>
                        <li>Health and Fitness ML Applications</li>
                        <li>Core ML Framework Development</li>
                      </ul>
                    </p>
                  </div>
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/nishkal-transformer-lecture.jpg`}
                      alt="Apple ML Program Overview"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                </div>

                <div className="split-item">
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/bootcamp.jpg`}
                      alt="Required Qualifications"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Required Qualifications</h3>
                    <p className="m-0">
                      Apple looks for ML interns with:
                      <ul>
                        <li>Strong programming skills in Python, C++, or Swift</li>
                        <li>Experience with ML frameworks (TensorFlow, PyTorch)</li>
                        <li>Background in deep learning, computer vision, or NLP</li>
                        <li>Excellent problem-solving abilities</li>
                        <li>Currently pursuing BS, MS, or PhD in Computer Science or related field</li>
                      </ul>
                    </p>
                  </div>
                </div>

                <div className="split-item">
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Application Process</h3>
                    <p className="m-0">
                      The Apple ML internship application process typically includes:
                      <ul>
                        <li>Online application submission</li>
                        <li>Technical phone screen</li>
                        <li>ML coding challenge</li>
                        <li>Virtual or onsite interviews</li>
                        <li>Team matching</li>
                      </ul>
                    </p>
                  </div>
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/calista-research-presentation.jpg`}
                      alt="Application Process"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                </div>

                <div className="split-item">
                  <div className="split-item-image center-content-mobile">
                    <Image
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}/belllecture.jpg`}
                      alt="Interview Preparation"
                      width={528}
                      height={396}
                      style={{objectFit: 'cover'}} />
                  </div>
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">Interview Preparation Tips</h3>
                    <p className="m-0">
                      Prepare for Apple's ML internship interviews by:
                      <ul>
                        <li>Reviewing ML fundamentals and algorithms</li>
                        <li>Practicing coding problems on platforms like LeetCode</li>
                        <li>Studying system design and ML system architecture</li>
                        <li>Reading Apple's ML research papers and blog posts</li>
                        <li>Preparing questions about Apple's ML initiatives</li>
                      </ul>
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </article>
 
        <FAQ />
      </main>
    );
  }
}

export default AppleMLInternships; 