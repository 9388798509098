import React from 'react';
// import section header
import SectionHeader from './partials/SectionHeader';
// import sections
import GenericSection from './GenericSection';
import Cta from './Cta';
// import some required elements
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import Accordion from '../elements/Accordion';
import AccordionItem from '../elements/AccordionItem';

class Secondary extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {

    const genericSection01Header = {
      title: 'Newsletter modal is a component commonly used'
    }    

    const genericSection02Header = {
      title: 'Button is a component commonly used'
    }

    const genericSection03Header = {
      title: 'Input form is a component commonly used'
    }

    const faqHeader = {
      title: 'Frequently asked questions'
    }    

    return (
      <React.Fragment>
        {/* <FAQHeader className="has-bg-color-cut illustration-section-02" /> */}
        {/* <Clients topDivider bottomDivider />
        <Team />
        <FeaturesSplit invertMobile imageFill topDivider /> */}

      <GenericSection hasBgColor invertColor>
          <div className="container-xs text-gray-200" id="FAQ">
           <SectionHeader data={faqHeader} className="center-content text-gray-200" />
              <Accordion>
              <AccordionItem title="When are applications due? When will we find out if we were accepted?" active>
                <div>
                  {/* <p>Launchpad Applications are now open for Summer 2025.</p> */}
                
                <p>Research Applications for 2025 will be accepted in two rounds. Students who apply during the early round (due February 28, 2025 at 11:59 PM PST) will be given priority. Regular round applications will remain open until April 30, 2025. Both rounds will be decided on a rolling basis</p>
                </div>
              </AccordionItem>
              <AccordionItem title="How much does BLAST cost? Are scholarships or financial aid available?">

                {/* <p>The 2-week BLAST AI Launchpad fees are $760.</p> */}
                <p>The 8-week BLAST AI Research Program fees will be $1460. </p>
                <p> Financial aid is available for both programs based on need. Historically, 30% of students attend on financial aid and we sincerely hope the fees do not stop you from attending. BLAST is committed to making AI research accessible, which is why we charge less than a fifth of the price of comparable programs.</p>
              </AccordionItem>
              <AccordionItem title="Can international students apply?" active>
                Absolutely! Students from 20+ countries have attended BLAST AI in the past, and we value a diverse set of backgrounds. For reference, meetings / lectures begin at 8 am PST to accommodate most timezones.
              </AccordionItem>
              <AccordionItem title="When is BLAST?">
                {/* <p>BLAST AI Launchpad runs in various 2-week periods throughout the summer.</p> */}
                <p>BLAST Research will run June 17, 2025 to Aug 12, 2025.</p>
              </AccordionItem>
              <AccordionItem title="I'd love to apply and join BLAST. However, I don't have much experience in AI or computer science.">
                We're looking for motivated, passionate, and talented students regardless of whether you have experience. BLAST will be rigorous, but if you think you can keep up with lessons and assignments, we definitely recommend applying.
              </AccordionItem>
              <AccordionItem title="Over the duration of BLAST, can I participate in other activities?">
                The bootcamp will include 4 hours of instruction each day, and you should allot 2-3 additional hours per day for homework, projects, and office hours. The research program will have structured lectures once a week, and mentors will meet with groups 2-3 times per week throughout the 8 weeks.
              </AccordionItem>
              {/* <AccordionItem title="I'm unsure how confident I am in my ML skills. Should I start with the Launchpad or Research?">
                <p>Programming and ML is taught from scratch in both programs. Although you will have to work much harder, we are looking for curiosity and persistance over experience.</p>
                <p>That being said, BLAST Research is quite selective and we are unable to accept the majority of applicants.</p>
                A good way to measure your ML ability and comprehension is to compete in Kaggle Competitions. We also reccomend working with LLMs and reading research papers. Our Research Program is centered around giving students real trust, and to do that we need to give you open-ended questions for group discovery. You may find your project unsuccessful if you don't have a solid understanding of AI fundamentals. 
              </AccordionItem> */}
            </Accordion>
          </div>
        </GenericSection>
      </React.Fragment>
    );
  }
}

export default Secondary;